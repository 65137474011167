import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
  Link,
} from "@mui/material";
import greenBox from "../images/greenBox.gif";
import { NavLink } from "react-router-dom";

const VideosSearchResultCard = (props) => {
  const [videoId, setVideoId] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [title, setTitle] = useState("");
  const [upvotes, setUpvotes] = useState(0);
  const [views, setViews] = useState(0);
  const [publishedOn, setPublishedOn] = useState("");
  const [owner, setOwner] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    // console.log(props);
    if (typeof props.videoInfo.id !== "undefined") {
      setVideoId(props.videoInfo.id);
    }
    if (typeof props.videoInfo.thumbnail !== "undefined") {
      setThumbnail(props.videoInfo.thumbnail);
    }
    if (typeof props.videoInfo.title !== "undefined") {
      setTitle(props.videoInfo.title);
    }
    if (typeof props.videoInfo.upvotes !== "undefined") {
      setUpvotes(props.videoInfo.upvotes);
    }
    if (typeof props.videoInfo.views !== "undefined") {
      setViews(props.videoInfo.views);
    }
    if (typeof props.videoInfo.uploaded_on !== "undefined") {
      setPublishedOn(props.videoInfo.uploaded_on);
    }
    if (typeof props.videoInfo.owner !== "undefined") {
      setOwner(props.videoInfo.owner);
    }
    if (typeof props.videoInfo.id !== "undefined") {
      setDescription(props.videoInfo.description);
    }
  }, [props.videoInfo]);

  const handleClick = () => {
    // console.log(
    //   "setting scroll with posts",
    //   props.scrollType,
    //   props.scrollPosition
    // );
    sessionStorage.setItem(
      `${props.scrollType}_scrollPosition`,
      props.scrollPosition.toString()
    );
  };

  // video id
  // thumbnail
  // title
  // upvotes
  // views
  // published date
  // owner
  // description

  const date = new Date(props.uploadedOn);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return (
    <>
      <Card
        sx={{
          width: "100%",
          // maxWidth: "500px",
          height: "10rem",
          // height: "200px",
          boxShadow: "none",
          // backgroundColor: "#F8F8F8",
          borderRadius: "25px",
          border: "1px solid #F8F8F8",
          // border: "1px solid #5F5F5F",
          mt: "7px",
        }}
      >
        <CardActionArea
          sx={{ width: "100%", height: "100%", minWidth: "260px" }}
          onClick={handleClick}
          component={NavLink}
          to={`/video/${videoId}`}
        >
          <CardContent sx={{ height: "100%", display: "flex", p: "3px" }}>
            <Box
              sx={{
                backgroundColor: "#EBF9FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30%",
                minWidth: "225px",
                borderRadius: "10px",
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                image={thumbnail}
                alt="Paella dish"
                sx={{
                  borderRadius: "10px",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "70%",
                px: "13px",
                py: "8px",
                // backgroundColor: "blue",
              }}
            >
              <Box
                sx={{ display: "flex", height: "15%", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "1rem",
                    fontWeight: "medium",
                    color: "black",
                    // color: "#5F5F5F",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box sx={{ height: "55%" }}>
                <Typography
                  sx={{
                    color: "GrayText",
                    fontSize: "0.7rem",
                    mt: "4px",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {description}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", height: "15%", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* Uploaded b */}
                  By&nbsp;
                </Typography>
                <Box
                  style={{ textDecoration: "none" }}
                  size="small"
                  // component={NavLink}
                  // to={`/profile/${props.noteInfo.note.owner.id}`}
                >
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      color: "GrayText",
                      fontFamily:
                        "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    }}
                  >
                    {owner}&nbsp;
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  on&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* {course.publish} 
                16 Mar, 2018 */}
                  {publishedOn}
                </Typography>
                {/* <Box sx={{}}>
                  <Typography sx={{ fontSize: "0.9em", mx: "3px" }}>
                    views {views}
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <Typography sx={{ fontSize: "0.9em", mx: "3px" }}>
                    upvotes {upvotes}
                  </Typography>
                </Box>
                <Box sx={{}}>
                  <Typography sx={{ fontSize: "0.9em", mx: "3px" }}>
                    Uploaded: {publishedOn}
                  </Typography>
                </Box> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "fit-content",
                  //  height: "15%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                    color: "GrayText",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    // fontFamily: "Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
                  }}
                >
                  {views}&nbsp;views, {upvotes}
                  &nbsp;Upvotes
                </Typography>

                {/* <Typography sx={{ fontSize: "1em", mx: "3px" }}>
                  {owner}
                </Typography> */}
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default VideosSearchResultCard;
