// import React from "react";

// const FeedSearchResultsComponent = (props) => {
//   return <div>FeedSearchResultsComponent {props.searchQuery}</div>;
// };

// export default FeedSearchResultsComponent;

import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import NotesSearchResultCard from "./NotesSearchResultCard";
import DefaultNotesCard from "./DefaultNotesCard";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import VideosSearchResultCard from "./VideosSearchResultCard";
import CircularProgress from "@mui/material/CircularProgress";
import { baseUrl } from "../services/baseUrl";
import ProfileSearchResultCard from "./ProfileSearchResultCard";

const FeedSearchResultsComponent = (props) => {
  const [searchResult, setSearchResult] = useState([]);
  const { access_token } = getToken();
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;
  const [scrollPosition, setScrollPosition] = useState("");
  const [scrollType, setScrollType] = useState("feed");
  const [offsetSuggestedUsers, setOffsetSuggestedUsers] = useState(0);
  const [
    isEmptyResponseSuggestedAccounts,
    setIsEmptyResponseSuggestedAccounts,
  ] = useState(false);
  const [suggestedAccounts, setSuggestedAccounts] = useState([]);
  const [isLoadingForSuggestedAccounts, setIsLoadingForSuggestedAccounts] =
    useState(true);
  const [hasMoreSuggestedAccounts, setHasMoreSuggestedAccounts] =
    useState(true);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const fetchData = async () => {
      setIsLoadingForSuggestedAccounts(true);

      try {
        const response = await fetch(
          `${baseUrl}/api/user/getsuggestedusers/?offset=${offsetSuggestedUsers}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            signal: abortController.signal,
          }
        );
        const data = await response.json();
        if (isMounted) {
          setSuggestedAccounts((prev) => [...prev, ...data.users]);
          setHasMoreSuggestedAccounts(data.users.length > 0);
          setIsLoadingForSuggestedAccounts(false);
          if (offsetSuggestedUsers === 0 && data.users.length === 0) {
            setIsEmptyResponseSuggestedAccounts(true);
          }
          // if (data.results.length > 0) {
          //   setIsSearchResultEmpty(false);
          // }
        }
        // console.log("this is feed data ", data.results);
      } catch (error) {
        console.error(error);
        setIsLoadingForSuggestedAccounts(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [offsetSuggestedUsers, access_token]);

  useEffect(() => {
    const handleInfiniteScroll = async () => {
      try {
        const scrollableDiv = document.getElementById(
          "suggested-accounts-list"
        );
        // console.log(scrollableDiv.scrollTop);
        // console.log(scrollableDiv.clientHeight);
        // console.log(scrollableDiv.scrollHeight);
        if (
          scrollableDiv &&
          scrollableDiv.scrollTop + scrollableDiv.clientHeight + 1 >=
            scrollableDiv.scrollHeight &&
          !isLoadingForSuggestedAccounts &&
          hasMoreSuggestedAccounts
        ) {
          setOffsetSuggestedUsers((prev) => prev + 20);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const scrollableDiv = document.getElementById("suggested-accounts-list");
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleInfiniteScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleInfiniteScroll);
      }
    };
  }, [isLoadingForSuggestedAccounts, isEmptyResponseSuggestedAccounts]);

  //searchquery in dependecy array , will call this useEffect when calling new element
  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    setOffset(0);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          // `http://127.0.0.1:8000/api/posts/getfeed/?q=${props.searchQuery}`,
          `${baseUrl}/api/posts/getfeed/?q=${props.searchQuery}&offset=${offset}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            signal: abortController.signal,
          }
        );
        const data = await response.json();
        if (isMounted) {
          setSearchResult(data.results);
          // setSearchResult((prev) => [...prev, ...data.results]);
          setHasMore(data.results.length === 20);
          setIsLoading(false);
        }
        // console.log("this is feed data ", data.results);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.searchQuery]);

  //offset in the dependecy array , while scrolling we will call this useEffect
  useEffect(() => {
    if (offset !== 0) {
      let isMounted = true;
      const abortController = new AbortController();

      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            // `http://127.0.0.1:8000/api/posts/getfeed/?q=${props.searchQuery}`,
            `${baseUrl}/api/posts/getfeed/?offset=${offset}&q=${props.searchQuery}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
              },
              signal: abortController.signal,
            }
          );
          const data = await response.json();
          if (isMounted) {
            if (typeof data.results !== "undefined") {
              setSearchResult((prev) => [...prev, ...data.results]);
              // setSearchResult((prev) => [...prev, ...data.results]);
              setHasMore(data.results.length === 20);
              setIsLoading(false);
            }
          }
          // console.log("this is feed data ", data.results);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      fetchData();

      return () => {
        isMounted = false;
        abortController.abort();
      };
    }
  }, [offset]);

  // useEffect(() => {
  //   setSearchResult([]);
  // }, [props.searchQuery]);

  useEffect(() => {
    const handleInfiniteScroll = async () => {
      try {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight &&
          !isLoading &&
          hasMore
        ) {
          // if (hasMore) {
          setOffset((prev) => prev + 20);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [isLoading, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("feed_scrollPosition");
    if (storedScrollPosition !== null) {
      const parsedScrollPosition = parseInt(storedScrollPosition, 10);
      setScrollPosition(parsedScrollPosition);
      // console.log("scroll position for default feed", parsedScrollPosition);
      // document.documentElement.scrollTop = scrollPosition;
      window.scrollTo(0, parseInt(scrollPosition));
    }
  }, [isLoading]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleInfiniteScroll);
  // }, []);

  return (
    <>
      <Grid
        container
        sx={{
          height: "auto",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          lg={3}
          md={0}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "blue",
            height: "auto",
          }}
        ></Grid>
        {/* {!isLoading ? ( */}
        <Grid
          item
          lg={6}
          md={8}
          sm={12}
          xs={12}
          sx={{
            // backgroundColor: "#D4F3FF",
            height: "auto",
            p: "8px",
          }}
        >
          {typeof searchResult !== "undefined"
            ? searchResult.map((post) => (
                <div key={post.id}>
                  <Box sx={{ p: "3.5px" }}>
                    {post.type === "note" ? (
                      <NotesSearchResultCard
                        noteInfo={post}
                        scrollType={scrollType}
                        scrollPosition={scrollPosition}
                      />
                    ) : (
                      <VideosSearchResultCard
                        videoInfo={post}
                        scrollType={scrollType}
                        scrollPosition={scrollPosition}
                      />
                    )}
                  </Box>
                </div>
              ))
            : ""}
          {!isLoading ? (
            ""
          ) : (
            <Box
              sx={{
                mt: "7px",
                width: "100%",
                p: "8px",
                borderRadius: "10px",
                ml: "8px",
                height: "160px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!hasMore ? (
            <Box
              sx={{
                mt: "7px",
                width: "100%",
                p: "8px",
                borderRadius: "10px",
                ml: "8px",
                height: "30px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.7rem",
                }}
              >
                That's all we have. New content is being uploaded on studnerd
                everyday.
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  display: "flex",
                  fontSize: "0.7rem",
                }}
              >
                {/* And our team is working round the clock to get you more quality
                content.  */}
                Thank you for choosing Studnerd!
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>
        {/* ) : (
          <Box
            sx={{
              mt: "8px",
              width: "100%",
              p: "10px",
              borderRadius: "10px",
              ml: "10px",
              height: "200px",
              // backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
        <Grid
          item
          lg={3}
          md={4}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "orange",
            // position: "fixed",
            p: "5px",
            width: "100%",
            right: "0",
            height: "fit-content", // add this
            // height: "100vh", // add this
            display: {
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontFamily: "Montserrat" }}>
            Suggested Accounts
          </Typography>
          <Box
            id="suggested-accounts-list"
            sx={{
              backgroundColor: "white",
              maxHeight: "calc(100vh - 100px)",
              p: "5px",
              overflowY: "auto",
            }}
          >
            {typeof suggestedAccounts !== "undefined"
              ? suggestedAccounts.map((result) => (
                  <div key={result.id}>
                    <Box sx={{ p: "6px" }}>
                      <ProfileSearchResultCard userInfo={result} />
                    </Box>
                  </div>
                ))
              : ""}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FeedSearchResultsComponent;

// {props.searchType === "notes" &&
// typeof noteSearchResult !== "undefined"
//   ? noteSearchResult.map((note) => (
//       <div key={note.id}>
//         <Box sx={{ p: "4px" }}>
//           <NotesSearchResultCard
//             id={note.id}
//             title={note.title}
//             owner={note.owner}
//             content={note.content}
//             uploadedOn={note.uploaded_on}
//             views={note.views}
//             upvotes={note.upvotes}
//           />
//         </Box>
//       </div>
//     ))
//   : ""}
