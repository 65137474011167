import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const ProfileSearchResultCard = (props) => {
  const [userId, setUserId] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [studnerdScore, setStudnerdScore] = useState(0.0);

  useEffect(() => {
    if (typeof props.userInfo.id !== "undefined") {
      setUserId(props.userInfo.id);
    }
    if (typeof props.userInfo.name !== "undefined") {
      setName(props.userInfo.name);
    }
    if (typeof props.userInfo.username !== "undefined") {
      setUsername(props.userInfo.username);
    }
    if (props.userInfo.profile !== null) {
      if (typeof props.userInfo.profile.profile_pic !== "undefined") {
        setProfilePic(props.userInfo.profile.profile_pic);
      }
      if (props.userInfo.profile.studnerdScore !== null) {
        if (typeof props.userInfo.profile.studnerdScore.score !== "undefined") {
          setStudnerdScore(
            Number(props.userInfo.profile.studnerdScore.score).toLocaleString(
              "en-US"
            )
          );
        }
      }
    }
  }, [props.userInfo]);
  return (
    <>
      <Card
        sx={{
          width: "100%",
          maxWidth: "450px",
          height: "75px",
          borderRadius: "10px",
          backgroundColor: "white",
          // backgroundColor: "#E9F7FF",
          // backgroundColor: "#DAECF7",
          // boxShadow: "-3px -3px 4px #FFFFFF, 2px 2px 3px #A5A6A7",
        }}
      >
        <CardActionArea
          sx={{ width: "100%", height: "100%", minWidth: "260px" }}
          component={NavLink}
          to={`/profile/${userId}`}
        >
          <CardContent sx={{ display: "flex", p: "4px", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "80px",
                p: "3px",
                // backgroundColor: "blue",
              }}
            >
              <Avatar src={profilePic} sx={{ height: "100%", width: "80%" }}>
                {/* {Array.from(name)[0]} */}
              </Avatar>
            </Box>
            <Box sx={{ px: "8px" }}>
              <Box>
                <Typography
                  sx={{
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.9rem",
                    fontweight: "semibold",
                  }}
                >
                  {name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    fontweight: "semibold",
                  }}
                >
                  @{username}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "0.7em",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontWeight: "light",
                  }}
                >
                  Studnerd Score {studnerdScore}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      {/* <Card
        sx={{
          width: "100%",
          maxWidth: "500px",
          // height: "75px",
        }}
      >
        <CardActionArea
          sx={{ width: "100%", height: "100%", minWidth: "260px" }}
        >
          <CardContent sx={{ display: "flex", p: "4px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80px",
                p: "3px",
              }}
            >
              <Avatar
                src={`${props.userdata.avararsrc}`}
                sx={{ height: "100%", width: "80%" }}
              >
                N
              </Avatar>
            </Box>
            <Box sx={{ px: "8px" }}>
              <Box>
                <Typography>
                  {`${props.userdata.firstname}`} {`${props.userdata.lastname}`}
                </Typography>
              </Box>
              <Box>
                <Typography>{`${props.userdata.username}`}</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "0.9em" }}>
                  Studnerd Score : {`${props.userdata.studnerdscore}`}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card> */}
    </>
  );
};

export default ProfileSearchResultCard;
