import {
  Box,
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import VideoJS from "../VideoJS";
import Navbar from "../Navbar";
import { NavLink } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReportIcon from "@mui/icons-material/Report";
import SuggestedVideos from "../SuggestedVideos";
import { getToken } from "../../services/CookieStorageService";
// import { getToken } from "../../services/LocalStorageService";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { baseUrl } from "../../services/baseUrl";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const VideoPage = () => {
  const { access_token } = getToken();
  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [VideoData, setVideoData] = useState([]);
  const playerRef = useRef(null);
  const playerRefForVote = useRef(null);
  const [suggestedVideos, setSuggestedVideos] = useState([]);
  const { video_id } = useParams();
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [openSb, setOpenSb] = useState(false);
  // const [open2, setOpen2] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpenSb(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSb(false);
  };
  const video_url = "";

  const handleUpvote = (event) => {
    event.preventDefault();
    // console.log(playerRefForVote.current);
    // const currentTime = playerRefForVote.current.currentTime() ;

    fetch(`${baseUrl}/api/posts/upvoteVideo/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
      body: JSON.stringify({
        postID: video_id,
      }),
    })
      .then((response) => {
        // console.log(data.message)
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);
            if (data.message === "rmup") {
              setUpvoted(false);
              setUpvoteCount((prevCount) => prevCount - 1);
            } else if (data.message === "aduprmdv") {
              setUpvoted(true);
              setDownvoted(false);
              setUpvoteCount((prevCount) => prevCount + 1);
            } else if (data.message === "adup") {
              setUpvoted(true);
              setUpvoteCount((prevCount) => prevCount + 1);
            }
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDownvote = (event) => {
    event.preventDefault();
    // const currentTime = playerRefForVote.current.currentTime();

    fetch(`${baseUrl}/api/posts/downvoteVideo/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
      body: JSON.stringify({
        postID: video_id,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);
            if (data.message === "rmupaddv") {
              setUpvoted(false);
              setDownvoted(true);
              setUpvoteCount((prevCount) => prevCount - 1);
            } else if (data.message === "rmdv") {
              setDownvoted(false);
            } else if (data.message === "addv") {
              setDownvoted(true);
            }
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSubmitReport = (event) => {
    event.preventDefault();
    // const currentTime = playerRefForVote.current.currentTime();
    const reason_to_report = selectedValue;
    // console.log("this is reason to report", selectedValue);

    fetch(`${baseUrl}/api/posts/report-video/${video_id}/${reason_to_report}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);
            setSelectedValue("");
            setOpenSb(true);
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    handleClose();
  };

  useEffect(() => {
    // axios
    //   .get(`${baseUrl}/api/posts/video/${video_id}`, {
    //     responseType: "blob",
    //     headers: {
    //       Authorization: `Bearer ${access_token}`,
    //     },
    //   })
    //   .then(function (response) {
    //      setVideoUrl(URL.createObjectURL(response.data));
    //     setIsVideoAvailable(true);

    // .catch(function (error) {
    //   // handle error
    //   console.log(error);
    // });

    axios
      .get(`${baseUrl}/api/posts/media-data/${video_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        setVideoData(response.data);
        setUpvoteCount(response.data.totalUpvotes);
        setUpvoted(response.data.is_upvoted);
        // setVideoUrl(video_url);
        setVideoUrl(response.data.resource_url);
        // setVideoUrl(
        //   "https://storage.googleapis.com/studnerd-prod-bucket/media/uploads/video_files/2023/12/26/Spider-Man.Across.The.Spider-Verse.2023.1080p.WEBRip.x264_3IcOU4k.mp4"
        // );

        setIsVideoAvailable(true);
        // console.log("this is response data", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [video_id]);

  useEffect(() => {
    //getsuggestedvideos
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/posts/getsuggestedvideos/${video_id}`,
          // `${baseUrl}/api/posts/getsuggestedvideos/?id=${video_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const data = await response.json();
        // console.log("suggested videos", data.videos);
        setSuggestedVideos(data.videos);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [video_id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const videoJsOptions = {
  //   autoplay: true,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   aspectRatio: "16:9",
  //   // playbackRates: "[0.5, 1, 1.5, 2]",
  //   sources: [
  //     {
  //       src: `http://127.0.0.1:8000/api/posts/video/${video_id}`,
  //       type: "video/mp4",
  //     },
  //   ],
  // };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    preload: "auto",
    // restoreEl: true,
    // responsive: true,
    // fluid: true,
    aspectRatio: "16:9",
    playbackRates: "[0.5, 1, 1.5, 2]",
    sources: [
      {
        src: videoUrl,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      // console.log("player is waiting");
    });

    player.on("dispose", () => {
      // console.log("player will dispose");
    });
  };

  return (
    <>
      <CssBaseline />
      <Navbar></Navbar>
      <Paper
        sx={{
          justifyContent: "center",
          // backgroundColor: "white",
          backgroundColor: "#F3F9FF",
          // backgroundColor: "#063050",

          minHeight: "100vh",
          height: "fit-content",
          // height: "calc(100vh - 48px)",
          pt: "48px",
        }}
      >
        <Snackbar
          open={openSb}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="warning"
            sx={{ width: "100%" }}
          >
            The Video has been Reported
          </Alert>
        </Snackbar>
        <Grid container spacing={2} display="flex">
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // width:"auto",
              // backgroundColor: "gray",
              // background: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
              // background: "linear-gradient(to top, #0f0c29, #302b63, #24243e)",

              // minHeight: "400px",
              // maxHeight: "500px",
              // maxWidth: "800px",
              height: "fit-content",
              pt: "4px",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "pink",
                width: "95%",
                // width: "900px",
                height: "auto",
                // height: "450px",

                // overflow: "hidden",
                position: "relative",
                // p: 0,
                // pl: "1rem",
                // pt: "1rem",
                // m: 0,
                // borderRadius: "15px",
              }}
            >
              {/* <VideoJS {...videoJsOptions} /> */}
              {isVideoAvailable ? (
                <VideoJS
                  options={videoJsOptions}
                  onReady={handlePlayerReady}
                  style={"border-radius:15px"}
                  // ref={playerRefForVote}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    minHeight: "60vh",
                    height: "100%",
                    backgroundColor: "black",
                    p: "10%",
                    mt: "1rem",
                    borderRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "1.2rem",
                      fontFamily: "OCR A",
                    }}
                  >
                    Please wait while the minions do their work...
                  </Typography>
                </Box>
                // <div>Waiting...</div>
              )}
            </Box>
            <Box
              sx={{
                mt: "5px",
                // backgroundColor: "white",
                // backgroundColor: "#D0D0D0",
                width: "95%",
                // width: "900px",
                height: "auto",
                // height: "450px",

                // overflow: "hidden",
                position: "relative",
                p: "10px",
                // border: "1px solid black",
                borderRadius: "4px",
                // pl: "1rem",
                // pt: "1rem",
                // m: 0,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  // backgroundColor: "yellow"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "Medium",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {VideoData.title}
                </Typography>
              </Box>
              <Grid container sx={{ width: "100%", display: "flex" }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={
                      {
                        //  backgroundColor: "yellowgreen"
                      }
                    }
                  >
                    <Box>
                      <Box
                        sx={{
                          justifyItems: "center",
                          display: "flex",
                          // pt: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.7rem",
                            color: "GrayText",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          By&nbsp;
                        </Typography>
                        <Link
                          style={{ textDecoration: "none" }}
                          size="small"
                          component={NavLink}
                          to={`/profile/${VideoData.owner_id}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.7rem",
                              fontFamily:
                                "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                            }}
                          >
                            {VideoData.owner}&nbsp;
                            {/* {owner.username} &nbsp; */}
                          </Typography>
                        </Link>
                        <Typography
                          sx={{
                            fontSize: "0.7rem",
                            color: "GrayText",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          On&nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.7rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          {VideoData.published}
                          {/* 16 Mar, 2018 */}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          fontFamily:
                            "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        }}
                      >
                        Total views: {VideoData.views}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                      // backgroundColor: "yellow",
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        sm: "flex-start",
                        md: "flex-end",
                        lg: "flex-end",
                      },
                    }}
                  >
                    {upvoted ? (
                      <Button
                        title="Remove upvote"
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid ",
                          mx: "4px",
                          my: "5px",
                          backgroundColor: "#037AB5",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#66ccff",
                            // boxShadow: "-4px -4px 6px #FFFFFF,4px 4px 6px #000000",
                          },
                        }}
                        size="small"
                        onClick={handleUpvote}
                        startIcon={<ArrowCircleUpIcon />}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textTransform: "none",
                            fontSize: "0.6rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          Upvote &nbsp;{upvoteCount}
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        title="Upvote Post"
                        sx={{
                          borderRadius: "15px",
                          border: "1px solid #037AB5",
                          mx: "4px",
                          my: "5px",
                          // backgroundColor: "white",
                          color: "#037AB5",
                        }}
                        size="small"
                        onClick={handleUpvote}
                        startIcon={<ArrowCircleUpIcon />}
                      >
                        <Typography
                          sx={{
                            color: "#66ccff",
                            textTransform: "none",
                            fontSize: "0.7rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          Upvote &nbsp;{upvoteCount}
                        </Typography>
                      </Button>
                    )}

                    <Button
                      title="Downvote Post"
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid gray",
                        mx: "4px",
                        my: "5px",
                        color: "gray",
                      }}
                      size="small"
                      onClick={handleDownvote}
                      startIcon={<ArrowCircleDownIcon />}
                    >
                      <Typography
                        sx={{
                          textTransform: "none",
                          fontSize: "0.7rem",
                          fontFamily:
                            "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        }}
                      >
                        Downvote
                      </Typography>
                    </Button>
                    <IconButton
                      title="Report Post"
                      aria-label="report"
                      // color="primary"
                      sx={{ mx: "4px" }}
                      onClick={handleClickOpen}
                    >
                      <ReportIcon />
                    </IconButton>
                    <Dialog open={open} onClose={handleClose}>
                      {/* <DialogTitle>Subscribe</DialogTitle> 
                      


                                                  {/* ('1', 'Spam'),
    ('2', 'Bullying or Harassment'),
    ('3', 'Copyright Infringement'),
    ('4', 'False Information'),
    ('5', 'Nudity or sexual Activity'),
    ('6', 'Hate speech or symbols'),
    ('7', 'Scams or fraud'),
    ('8', 'Violence or Self-injury'), */}
                      <DialogContent>
                        <DialogContentText>
                          Select Reason to Report the Video
                        </DialogContentText>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={selectedValue}
                            onChange={(event) =>
                              setSelectedValue(event.target.value)
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Spam"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="Bullying or Harassment"
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio />}
                              label="Copyright Infringement"
                            />
                            <FormControlLabel
                              value="4"
                              control={<Radio />}
                              label="False Information"
                            />
                            <FormControlLabel
                              value="5"
                              control={<Radio />}
                              label="Nudity or sexual Activity"
                            />

                            <FormControlLabel
                              value="6"
                              control={<Radio />}
                              label="Hate speech or symbols"
                            />
                            <FormControlLabel
                              value="7"
                              control={<Radio />}
                              label="Scams or fraud"
                            />
                            <FormControlLabel
                              value="8"
                              control={<Radio />}
                              label="Violence or Self-injury"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* add form here  */}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmitReport}>Submit</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Box
                sx={{
                  width: "100%",
                  // backgroundColor: "blue",
                  py: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "25px",
                    p: "15px",
                    px: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      color: "#888888",
                      fontWeight: "medium",
                      fontFamily:
                        "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    }}
                  >
                    Description
                  </Typography>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontFamily:
                          "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      }}
                    >
                      {VideoData.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              width: "95%",
              flexDirection: "column",
              justifyContent: "center",
              // backgroundColor: "gray",
              // alignItems: "center",

              height: "fit-content",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                // backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                px: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  pt: "10px",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Suggested Videos
              </Typography>
              <Divider sx={{ width: "95%" }} />
              {suggestedVideos.length > 0 ? (
                suggestedVideos.map((video) => (
                  <Box key={video.id} sx={{ p: "4px" }}>
                    <SuggestedVideos videoInfo={video} key={video.id} />
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    backgroundColor: "white",
                    height: "fit-content",
                    width: "100%",
                    p: "15px",
                    borderRadius: "10px",
                    my: "10px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "0.7rem", fontFamily: "sans-serif" }}
                  >
                    Couldn't find any related videos :/ Don't worry we are
                    working on getting more content. Please check back later
                  </Typography>
                </Box>
              )}
              {/* <Box sx={{ p: "4px" }}>
              <SuggestedVideos />
              </Box>
              <Box sx={{ p: "4px" }}>
              <SuggestedVideos />
              </Box>
              <Box sx={{ p: "4px" }}>
              <SuggestedVideos />
            </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default VideoPage;
