import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter, Router } from "react-router-dom";
// import { GoogleOAuthProvider } from "@react-oauth/google";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>
//   // </React.StrictMode>
// );
// const google_clientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
const rootNode = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    {/* <GoogleOAuthProvider clientId={google_clientID}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </GoogleOAuthProvider> */}
  </Provider>,
  rootNode
);
reportWebVitals();
