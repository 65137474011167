// A Beginners Guide on How to use Studnerd
import React from "react";
import {
  Box,
  Paper,
  CssBaseline,
  Grid,
  Divider,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo.png";

import blogsbg from "../../../images/studnerdblogs.png";
import Footer from "../../Footer";
import videoForm from "../../../images/videoForm.PNG";
import scene4 from "../../../staticfiles/scene4.PNG";

const SecondBlog = () => {
  return (
    <>
      <CssBaseline></CssBaseline>
      {/* <BlankNavbar /> */}
      <Paper
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          //   backgroundColor: "#E9F7FF",
          backgroundImage: `url(${blogsbg})`,
          backgroundSize: "cover", // Stretch the image to cover the entire container
          backgroundAttachment: "fixed",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "normal",
            height: "auto",
            // backgroundColor: "black",
            // minHeight: "100vh",
            // backgroundColor: "rgba(233, 247, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            // p: "15px",
          }}
        >
          <Box
            sx={{
              alignContent: "right",
              width: "100%",
              // backgroundColor: "red",
              // backgroundColor: "rgba(255, 255, 255, 0.4)",
              // backdropFilter: "blur(5px)",
              height: "fit-content",
              p: "10px",
              //   display: "flex",
              //   justifyContent: "flex-end",
            }}
          >
            <Button
              component={Link}
              to="/blogs"
              sx={{
                textTransform: "none",
                borderRadius: "30px",
                p: "4px",
                px: "10px",
                fontWeight: "medium",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "1rem",
                backgroundColor: "white",
                border: "2px solid #66ccff",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  border: "2px solid black",
                  color: "#66ccff",
                  transition: "0.3s",
                },
              }}
            >
              Go to Blogs
            </Button>
            <Button
              component={Link}
              to="/"
              sx={{
                textTransform: "none",
                borderRadius: "30px",
                ml: "10px",
                p: "4px",
                px: "10px",
                fontWeight: "medium",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "1rem",
                backgroundColor: "#66ccff",
                border: "2px solid white",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  border: "2px solid #66ccff",
                  color: "#66ccff",
                  transition: "0.3s",
                },
              }}
            >
              Visit studnerd.com
            </Button>
          </Box>
          <Grid
            item
            lg={8}
            md={10}
            sm={12}
            xs={12}
            // id="container"
            sx={{
              // position: "relative",
              // backgroundColor: "red",
              // px: "20px",
              // justifyContent: "center",
              alignItems: "center",
              // display: "flex",
              borderRadius: "20px",
              // pt: "10px",
              px: "35px",
              mb: "50px",
              // p: 0,
              // m: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "white",
                // backgroundColor: "rgba(255, 255, 255, 0.4)",
                backgroundColor: "rgba(100, 220,255, 0.1)",
                backdropFilter: "blur(6px)",
                px: "15px",
                py: "20px",
                borderRadius: "15px",
                textDecoration: "none",
                color: "",
                // color: "#474747",
                my: "15px",
                // transition: "0.8s",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }}
              >
                Creating video tutorials: A brief overview
              </Typography>

              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontFamily: "Montserrat",
                  color: "#747474",
                }}
              >
                By Vaibhav Rathod, 24<sup>th</sup> Jan, 2024
              </Typography>
              <Divider sx={{ my: "4px" }} />

              <Box sx={{ p: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  In this blog, we will learn how to upload video tutorials on
                  studnerd.
                </Typography>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  You must be familiar with the features that studnerd has to
                  offer, if not please check the{" "}
                  <Link to="/about-us">about us</Link> page of the website.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  One such feature is for users to be able to upload and browse
                  a video.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="video page"
                    src={scene4}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  In order to create a video first click on "+" button on home
                  screen and select create video tab.
                </Typography>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Following image shows how the Create Video form looks.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="video upload form"
                    src={videoForm}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Remeber the follow the following, for uploading video
                  tutorial.
                  <ul>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Title, Video file and Thumbnail cannot be empty.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Make sure to read the community guidelines before
                      uploading the video file.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Title of the video cannot be more than 200 characters in
                      length
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Video file cannot be more than 1.5 GB in size, and
                      thumbnail cannot be more than 2 MB in size.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Tags, which is not compulsory, cannot be more than 100
                      characters in length.
                    </ListItem>
                  </ul>
                </Typography>

                <br></br>

                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  There are a few types of video tutorials based on teaching
                  style. They are as follows:
                  <br></br>
                  <ul>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Presentation
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      White Board
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Paper
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Drawing Sheet
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Animated
                    </ListItem>
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Footer />
    </>
  );
};

export default SecondBlog;
