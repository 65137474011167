import React, { useState } from "react";
import { Typography, Box, Divider, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUserId, selectAccessToken } from "../features/authSlice";
import { removeToken, getToken } from "../services/CookieStorageService";
import axios from "axios";
import { baseUrl } from "../services/baseUrl";
import { logout } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SettingsAccount = () => {
  const [open, setOpen] = useState(false);
  const [openForError, setOpenForError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // let currentUserId = useSelector(selectCurrentUserId);
  const { access_token } = getToken();

  const handleCloseforErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenForError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (event) => {
    try {
      event.preventDefault();
      const response = await axios.post(
        `${baseUrl}/api/user/delete-me/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      // console.log("after deletion", response);

      dispatch(logout());
      removeToken();
      navigate("/login");
    } catch (error) {}

    // delete-me/<id>

    //call and delete the user
    //logout the user
    //navigate to user login page
    // logout;
    setOpen(false);
    setOpenForError(true);
  };
  return (
    <>
      <Snackbar
        open={openForError}
        autoHideDuration={6000}
        onClose={handleCloseforErrorSnackbar}
      >
        <Alert
          onClose={handleCloseforErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error while uploading. Try Again.
        </Alert>
      </Snackbar>
      <Box
        id="test2"
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          p: "8px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <Typography
          sx={{
            my: "4px",
            fontWeight: "bold",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          Account settings
        </Typography>
        <Divider />
        <Box sx={{ minHeight: "40px", display: "block", py: "8px" }}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              fontFamily:
                "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
            }}
          >
            Deactivate your Studnerd Account
          </Typography>
          <Box sx={{ pt: "10px" }}>
            {" "}
            <Button
              onClick={handleClickOpen}
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #9B9B9B",
                backgroundColor: "#EA4335",
                "&:hover": {
                  backgroundColor: "#D82A1B",
                  boxShadow: "-5px -5px 6px #FFFFFF, 5px 5px 6px #9B9B9B",
                },
                // background:
                //   "linear-gradient(119deg, rgba(251,148,148,1) 0%, rgba(253,55,55,1) 100%)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Delete Account
              </Typography>
            </Button>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            backdropFilter: "blur(5px)",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontSize: "1rem",
              fontFamily:
                "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
            }}
          >
            {"Are you sure you want to delete your account?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              // sx={{ fontSize: "1rem" }}
            >
              {/* <Typography sx={{ fontFamily: "Segoe UI", fontSize: "1rem" }}>
                We are sad to see you go :(
              </Typography> */}
              <Typography sx={{ fontFamily: "Segoe UI", fontSize: "0.8rem" }}>
                Studnerd will retain you information for 14 days, after that all
                your data will be deleted permanently. Your Username cannot be
                used by any other account for this time. Meanwhile, you can
                login into your account in next 14 days to activate it again :D
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                textTransform: "none",
                // backgroundColor
              }}
            >
              <Typography sx={{ fontSize: "0.8rem", fontFamily: "montserrat" }}>
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "orange",
                },
              }}
            >
              <Typography sx={{ fontSize: "0.8rem", fontFamily: "montserrat" }}>
                Delete
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default SettingsAccount;
