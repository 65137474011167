import { Typography, Grid, Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar";
import ProfileSearchResultCard from "../ProfileSearchResultCard";
import VideosSearchResultCard from "../VideosSearchResultCard";
import NotesSearchResultCard from "../NotesSearchResultCard";
import { getToken } from "../../services/CookieStorageService";
// import { getToken } from "../../services/LocalStorageService";
import DefaultFieldComponent from "../DefaultFieldComponent";
import DefaultVideosComponent from "../DefaultVideosComponent";
import DefaultNotesComponent from "../DefaultNotesComponent";
import VideosSearchResultsComponent from "../VideosSearchResultsComponent";
import FeedSearchResultsComponent from "../FeedSearchResultsComponent";
import NotesSearchResultsComponent from "../NotesSearchResultsComponent";
import { selectCurrentSelectedTopicQuery } from "../../features/homeStateSlice";
import { useSelector, useDispatch } from "react-redux";

const SearchResults = (props) => {
  const { access_token } = getToken();

  const [searchQuery, setSearchQuery] = useState("");
  const [tabSelected, setTabSelected] = useState("");
  const [isCourseLoaded, setisCourseLoaded] = useState(false);
  const [videoSearchResult, setvideoSearchResult] = useState([]);
  const [noteSearchResult, setnoteSearchResult] = useState([]);
  const selectedTopicQuery = useSelector(selectCurrentSelectedTopicQuery);

  useEffect(() => {
    if (props.searchQuery) {
      setSearchQuery(props.searchQuery);
    }

    if (props.searchType) {
      setTabSelected(props.searchType);
    }
    if (props.isCourseLoaded) {
      setisCourseLoaded(props.isCourseLoaded);
    }
  }, [props.searchQuery, props.searchType, props.isCourseLoaded]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: "96px",
          minHeight: "100vh",
          // minHeight: "calc(100vh - 96px)",
          height: "fit-content",
          // backgroundColor: "#063050",
          // backgroundColor: "#DADADA",
          backgroundColor: "#F3F9FF",
        }}
      >
        {/* {props.searchType === "feed" ? (
          <DefaultFieldComponent searchQuery={searchQuery} />
        ) : (
          ""
        )} */}
        {props.searchType === "feed" && (
          <>
            {props.searchQuery !== "" ? (
              <FeedSearchResultsComponent searchQuery={searchQuery} />
            ) : (
              <DefaultFieldComponent />
            )}
          </>
        )}
        {props.searchType === "videos" && (
          <>
            {props.searchQuery !== "" ? (
              <VideosSearchResultsComponent searchQuery={searchQuery} />
            ) : (
              <DefaultVideosComponent />
            )}
          </>
        )}
        {props.searchType === "notes" && (
          <>
            {props.searchQuery !== "" ? (
              <NotesSearchResultsComponent searchQuery={searchQuery} />
            ) : (
              <DefaultNotesComponent />
            )}
          </>
        )}
        {/* {props.searchType === "notes" ? (
          <>
            <DefaultNotesComponent searchQuery={searchQuery} />
          </>
        ) : (
          ""
        )} */}
      </Box>
    </>
  );
};

export default SearchResults;
