import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";

import React, { Suspense, useEffect, useState } from "react";
// import { setUserInfo } from "./features/userSlice";
// import { useGetLoggedUserQuery } from "./services/userAuthApi";
import { getToken, removeToken } from "./services/CookieStorageService";
// import { getToken, removeToken } from "./services/LocalStorageService";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
// import PrivateRoutes from "./utils/PrivateRoute";
// import { AuthInitializer } from "./features/InitializeAction";

// import greenBox from "./images/greenBox.gif";
import NoteViewer from "./components/pages/NoteViewer";
import { logout } from "./features/authSlice";
import AccountSettings from "./components/pages/AccountSettings";
import LoadingComponent from "./components/pages/LoadingComponent";
import VideoPage from "./components/pages/VideoPage";
import NoInternetConnection from "./components/NoInternetConnection";
import Login from "./components/pages/Login";
import NoInternetPage from "./components/pages/NoInternetPage";
import FirstBlog from "./components/pages/blogs/FirstBlog";
import SecondBlog from "./components/pages/blogs/SecondBlog";
import ThirdBlog from "./components/pages/blogs/ThirdBlog";
import Cookies from "js-cookie";
import { gapi } from "gapi-script";
// import Home from "./components/pages/Home";
import SetUsernamePage from "./components/pages/SetUsernamePage";

// import { useLocation } from "react-router-dom";
// import { selectIsAuthenticated } from "./features/authSlice";
// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const drfClientId = process.env.REACT_APP_DRF_CLIENT_ID;
// const drfClientSecret = process.env.REACT_APP_DRF_CLIENT_SECRET;
// const baseURL = "http://localhost:8000";
// import Cookies from "js-cookie";

// import LoginRegister from "./components/pages/LoginRegister";
const LoginRegister = React.lazy(() =>
  import("./components/pages/LoginRegister")
);

// import ForgotPassword from "./components/pages/ForgotPassword";
const ForgotPassword = React.lazy(() =>
  import("./components/pages/ForgotPassword")
);

// import ChangePassword from "./components/pages/ChangePassword";
const TermsOfServicePage = React.lazy(() =>
  import("./components/pages/TermsOfServicePage")
);
const PrivacyPolicyPage = React.lazy(() =>
  import("./components/pages/PrivacyPolicyPage")
);
const InvestorsPage = React.lazy(() =>
  import("./components/pages/InvestorsPage")
);
const BlogsPage = React.lazy(() => import("./components/pages/BlogsPage"));
const AboutUsPage = React.lazy(() => import("./components/pages/AboutUsPage"));
const HelpPage = React.lazy(() => import("./components/pages/HelpPage"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Home = React.lazy(() => import("./components/pages/Home"));
const Videos = React.lazy(() => import("./components/pages/Videos"));
const Notes = React.lazy(() => import("./components/pages/Notes"));
const SyllabusViewer = React.lazy(() =>
  import("./components/pages/SyllabusViewer")
);
const ImportSyllabus = React.lazy(() =>
  import("./components/pages/ImportSyllabus")
);
const CreateSyllabus = React.lazy(() =>
  import("./components/pages/CreateSyllabus")
);
const Profile = React.lazy(() => import("./components/pages/Profile"));
const CreatePost = React.lazy(() => import("./components/pages/CreatePost"));
const ChangePassword = React.lazy(() =>
  import("./components/pages/ChangePassword")
);
// const AccountEdit = React.lazy(() => import("./components/pages/AccountEdit"));
const Notifications = React.lazy(() =>
  import("./components/pages/Notifications")
);
const CreateNote = React.lazy(() => import("./components/pages/CreateNote"));
const CreateVideo = React.lazy(() => import("./components/pages/CreateVideo"));
const Editpost = React.lazy(() => import("./components/Editpost"));
const ViewNote = React.lazy(() => import("./components/ViewNote"));
const ResetPassword = React.lazy(() =>
  import("./components/pages/ResetPassword")
);
const SearchQueryResult = React.lazy(() =>
  import("./components/pages/SearchQueryResult")
);
const CourseSearchResult = React.lazy(() =>
  import("./components/pages/CourseSearchResult")
);
const PrivateRoutes = React.lazy(() => import("./utils/PrivateRoute"));
// const handleGoogleLogin = (response) => {
//   axios
//     .post(`${baseURL}/auth/convert-token`, {
//       token: response.accessToken,
//       backend: "google-oauth2",
//       grant_type: "convert_token",
//       client_id: drfClientId,
//       client_secret: drfClientSecret,
//     })
//     .then((res) => {
//       const { access_token, refresh_token } = res.data;
//       console.log({ access_token, refresh_token });
//       Cookies.set("access_token", access_token);
//       Cookies.set("refresh_token", refresh_token);
//     })
//     .catch((err) => {
//       console.log("Error Google login", err);
//     });
// };
const google_clientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

function App() {
  const { access_token } = getToken();
  // const [accessToken, setAccessToken] = useState(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: google_clientID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  //create a constant array and checkk open repos and see the code based on that.
  // useEffect(() => {
  //   const accessToken = Cookies.get("access_token");
  //   setAccessToken(accessToken);
  // }, []);

  // useEffect(() => {
  //   const authenticate = async () => {
  //     try {
  //       AuthInitializer(dispatch);

  //       // setIsAuthenticated(isAuthenticated);
  //     } catch (error) {
  //       // if (error.message === "Invalid token specified") {
  //       //   dispatch(logout());
  //       //   removeToken();
  //       //   navigate("/login");
  //       // }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   authenticate();
  // }, [dispatch]);

  if (loading) {
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  }

  return (
    // <NoInternetConnection>
    <Suspense fallback={<LoadingComponent />}>
      <Routes>
        {/* <Route path="" element={<Videos/>}/> */}
        <Route
          path="/login"
          element={<Login />}
          // element={!access_token ? <Login /> : <Navigate to="/home" />}
        />

        {/* <Route path="/abc" element={<NoInternetPage />} /> */}
        {/* <Route path="/maxpayne" element={<Login />} /> */}
        <Route path="/signup" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/api/user/reset/:id/:token" element={<ResetPassword />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        {/* <Route
            path="/home"
            element={access_token ? <Home /> : <Navigate to="/login" />}
            exact
          /> */}
        <Route element={<PrivateRoutes />}>
          {/* <Route element={<PrivateRoutes dispatch={dispatch} />}> */}
          <Route path="/set-username" element={<SetUsernamePage />} />
          <Route path="/Note/:postID" element={<NoteViewer />} />
          <Route path="/video/:video_id" element={<VideoPage />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route element={<Home />} path="/home" exact />
          <Route element={<Home />} path="/" exact />
          <Route element={<CreatePost />} path="/create" />
          {/* <Route path="/create-note" element={<CreateNote />} /> */}
          <Route path="/EditNote/:postID" element={<Editpost />} />
          <Route path="/ViewNote/:postID" element={<ViewNote />} />
          {/* <Route path="/create-video" element={<CreateVideo />} /> */}
          {/* <Route path="/videoxyz/:video_id" element={<Video />} /> */}
          {/* <Route path="/notes/:id" element={<ViewNote />} /> */}
          {/* <Route path="/note/:id" element={<NoteViewer/>} /> */}
          <Route path="/videos" element={<Videos />} />
          <Route path="/notes" element={<Notes />} />{" "}
          <Route path="/search" element={<SearchQueryResult />} />{" "}
          <Route path="/path-search" element={<CourseSearchResult />} />{" "}
          {/*search result for notes */}
          {/* <Route path="/profiles" element={<Profiles/>} />  search result for profiles */}
          {/* <Route path="/account/edit" element={<AccountEdit />} /> */}
          <Route path="/account/settings" element={<AccountSettings />} />
          {/* <Route path="/email/settings" element={<EditEmailSettings/>} />
        <Route path="/account/privacy-and-security" element={<EditPrivacyAndSecurity/>} />
      <Route path="/account/push-notifications" element={<EditPushNotifications/>} />*/}
          <Route path="/loadingPage" element={<LoadingComponent />} />
          <Route path="/import" element={<ImportSyllabus />} exact />
          <Route path="/create-syllabus" element={<CreateSyllabus />} />
          {/* <PrivateRoute path="/create-syllabus" element={<CreateSyllabus />} /> */}
          <Route path="/profile/:id" element={<Profile />} />{" "}
          <Route path="/course/:course_id" element={<SyllabusViewer />} />
        </Route>
        <Route path="/help" element={<HelpPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/investors" element={<InvestorsPage />} />
        {/* <Route path="/ceo" element={<InvestorsPage />} /> */}
        <Route path="/blogs/how-to-use-studnerd" element={<FirstBlog />} />
        <Route
          path="/blogs/creating-video-tutorials"
          element={<SecondBlog />}
        />
        <Route
          path="/blogs/writing-notes-editor-walkthrough"
          element={<ThirdBlog />}
        />
        {/* <Route path="/notifications" element={<Notifications />} /> */}
        {/* <Route path="demo" element={}</> */}
      </Routes>
    </Suspense>
    // </NoInternetConnection>
  );
}

export default App;
