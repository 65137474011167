import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import VideosSearchResultCard from "./VideosSearchResultCard";
import CircularProgress from "@mui/material/CircularProgress";
import { getToken } from "../services/CookieStorageService";
import { baseUrl } from "../services/baseUrl";

const VideosSearchResultsComponent = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [scrollPosition, setScrollPosition] = useState("");
  const [scrollType, setScrollType] = useState("videos");
  const { access_token } = getToken();

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    setOffset(0);

    // console.log(access_token);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/api/posts/searchvideofromcourse/?q=${props.searchQuery}&offset=${offset}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            signal: abortController.signal,
          }
        );
        const data = await response.json();
        // console.log("data for videos xxxxxxxxx", data);
        if (isMounted) {
          setVideos(data.videos);
          setHasMore(data.videos.length === 20);
          setIsLoading(false);
        } // console.log("videos saved", data.videos);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
    // setSearchQuery(props.searchQuery);
  }, [props.searchQuery]);

  useEffect(() => {
    if (offset !== 0) {
      let isMounted = true;
      const abortController = new AbortController();
      // console.log(access_token);
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${baseUrl}/api/posts/searchvideofromcourse/?offset=${offset}&q=${props.searchQuery}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // Authorization: `Bearer ${access_token}`,
              },
              signal: abortController.signal,
            }
          );
          const data = await response.json();
          // console.log("data for videos xxxxxxxxx", data);
          if (isMounted) {
            if (typeof data.videos !== "undefined") {
              setVideos((prev) => [...prev, ...data.videos]);
              setHasMore(data.videos.length === 20);
              setIsLoading(false);
            }
          } // console.log("videos saved", data.videos);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      fetchData();

      return () => {
        isMounted = false;
        abortController.abort();
      };
    }
    // setSearchQuery(props.searchQuery);
  }, [offset]);

  useEffect(() => {
    const handleInfiniteScroll = async () => {
      try {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight &&
          !isLoading &&
          hasMore
        ) {
          // if (hasMore) {
          setOffset((prev) => prev + 20);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [isLoading, hasMore]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleInfiniteScroll);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem(
      "videos_scrollPosition"
    );
    if (storedScrollPosition !== null) {
      const parsedScrollPosition = parseInt(storedScrollPosition, 10);
      setScrollPosition(parsedScrollPosition);
      // console.log("scroll position for default feed", parsedScrollPosition);
      // document.documentElement.scrollTop = scrollPosition;
      window.scrollTo(0, parseInt(scrollPosition));
    }
  }, [isLoading]);

  return (
    <>
      <Grid
        container
        sx={{
          height: "auto",
          //  justifyContent: "center"
        }}
      >
        <Grid
          item
          lg={3}
          md={0}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "blue",
            height: "auto",
          }}
        ></Grid>
        {/* {!isLoading ? ( */}
        <Grid
          item
          lg={6}
          md={8}
          sm={12}
          xs={12}
          sx={{
            // backgroundColor: "#D4F3FF",
            height: "auto",
            p: "8px",
          }}
        >
          {videos.map((video) => (
            <Box sx={{ p: "3.5px", m: "8px" }}>
              <div key={video.id}>
                <VideosSearchResultCard
                  videoInfo={video}
                  scrollType={scrollType}
                  scrollPosition={scrollPosition}
                />
              </div>
            </Box>
          ))}
          {!isLoading ? (
            ""
          ) : (
            <Box
              sx={{
                mt: "7px",
                width: "100%",
                p: "8px",
                borderRadius: "10px",
                ml: "8px",
                height: "160px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!hasMore ? (
            <Box
              sx={{
                mt: "7px",
                width: "100%",
                p: "8px",
                borderRadius: "10px",
                ml: "8px",
                height: "30px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.7rem",
                }}
              >
                That's all we have. New content is being uploaded on studnerd
                everyday.
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  display: "flex",
                  fontSize: "0.7rem",
                }}
              >
                {/* And our team is working round the clock to get you more quality
                content.  */}
                Thank you for choosing Studnerd!
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>
        {/* ) : (
          <Box
            sx={{
              mt: "8px",
              width: "100%",
              p: "10px",
              borderRadius: "10px",
              ml: "10px",
              height: "200px",
              // backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box> */}
        {/* // <Box
          //   sx={{
          //     height: "calc(100vh-96px)",
          //     width: "100%",
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //   }}
          // >
          //   <CircularProgress />
          // </Box> */}
        {/* )} */}

        <Grid
          item
          lg={3}
          md={4}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "orange",
            position: "fixed",

            width: "100%",
            right: "0",
            height: "fit-content", // add this
            // height: "100vh", // add this
            display: {
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Box
            sx={{
              top: "0",
              // overflowY: "auto",
              position: "sticky",
              // maxHeight: "calc(100vh - 64px)",
              height: "100%",
            }}
          >
            {/* <Grid
          item
          lg={3}
          md={4}
          sm={0}
          xs={0}
          sx={{
            backgroundColor: "orange",
            position: "sticky",
            // position: "fixed",
            width: "100%",
            right: "0",
            top: "0",
            height: "fit-content",
            // maxHeight: "calc(100vh - 64px)",
            overflowY: "auto",
            display: {
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          uieasd iuef iuhdfi uesi ufh
          <Box sx={{ position: "relative", height: "100%" }}> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default VideosSearchResultsComponent;
