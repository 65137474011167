import { createSlice } from "@reduxjs/toolkit";

export const treeSlice = createSlice({
  name: "tree",
  initialState: {
    isCourseLoaded: false,
    course: null,
  },
  reducers: {
    setCurrentCourse: (state, action) => {
      state.isCourseLoaded = action.payload.isCourseLoaded;
      state.course = action.payload.course;
    },
  },
});

export const { setCurrentCourse } = treeSlice.actions;

export const selectTree = (state) => state.tree;
export const selectCurrentCourse = (state) => state.tree.course;
export const selectIsCourseLoaded = (state) => state.tree.isCourseLoaded;

export default treeSlice.reducer;
