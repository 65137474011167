import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Box, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
const DefaultVideoCard = (props) => {
  const [thumbnail, setThumbnail] = useState("");
  // console.log(props);
  // useEffect(() => {
  //   if (props.video.thumbnail) {
  //     setThumbnail(props.video.thumbnail);
  //   }
  // }, [props.video]);

  const handleClick = () => {
    // console.log(
    //   "setting scroll with posts",
    //   props.scrollType,
    //   props.scrollPosition
    // );
    sessionStorage.setItem(
      `${props.scrollType}_scrollPosition`,
      props.scrollPosition.toString()
    );
  };
  return (
    <>
      <Card
        sx={{
          // backgroundColor: "#F8F8F8",
          // backgroundColor: "#EBF9FF",
          width: "100%",
          height: "100%",
          // maxWidth: "500px",
          // maxHeight: "350px",
          // maxWidth: "380px",
          boxShadow: "none",
          px: "4px",
          pt: "4px",
          borderRadius: "10px",
          border: "1px solid #F8F8F8",
        }}
      >
        <CardActionArea
          disableRipple
          onClick={handleClick}
          component={NavLink}
          to={`/video/${props.videoInfo.video.id}`}
          sx={{
            borderRadius: "10px",
            height: "100%",
            width: "100%",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CardMedia
            component="img"
            // height="220"
            loading="lazy"
            image={props.videoInfo.video.thumbnail}
            alt="video thumbnail"
            sx={{
              p: "4px",
              height: "20vh",
              minHeight: "210px",
              // maxHeight: "220px",
              objectFit: "contain",
              borderRadius: "10px",
              backgroundColor: "white",
            }}
          />
          <CardContent sx={{ m: 0, p: "8px" }}>
            <Typography
              title={props.videoInfo.video.title}
              // variant="h6" component="div"
              sx={{
                // color: "#5F5F5F",
                fontSize: "0.9rem",
                // fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              {props.videoInfo.video.title}
            </Typography>

            <Box
              sx={{
                justifyItems: "center",
                display: "flex",
                // pt: "5px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "GrayText",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {/* Uploaded b */}
                By&nbsp;
              </Typography>
              <Box sx={{ textDecoration: "none" }} size="small">
                <Typography
                  // component={NavLink}
                  // to={`/profile/${props.videoInfo.video.owner.id}`}
                  sx={{
                    fontSize: "0.7rem",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    // textTransform: "none",
                    textDecoration: "none",
                    color: "gray",
                  }}
                >
                  {props.videoInfo.video.owner.username}&nbsp;
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "GrayText",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                On&nbsp;
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "GrayText",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {props.videoInfo.video.published}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: "0.6rem",
                color: "GrayText",
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              {props.videoInfo.view_count}&nbsp;Views,{" "}
              {props.videoInfo.total_upvotes}&nbsp;Upvotes
            </Typography>
          </CardContent>
        </CardActionArea>
        {/* <CardActions> */}
        {/* </CardActions> */}
      </Card>
    </>
  );
};

export default DefaultVideoCard;
