import React, { useEffect, useState } from "react";
import { Typography, Box, Divider, Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import { baseUrl } from "../services/baseUrl";

const SettingsNotifications = (props) => {
  const { access_token } = getToken();
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);

  const [notificationForFollowers, setNotificationForFollowers] =
    useState(false);
  const [notificationForUpvotes, setNotificationForUpvotes] = useState(false);
  const [notificationForSyllabusImports, setNotificationForSyllabusImports] =
    useState(false);

  useEffect(() => {
    setNotificationForFollowers(props.notificationForFollowers);
    setNotificationForUpvotes(props.notificationForUpvotes);
    setNotificationForSyllabusImports(props.notificationForSyllabusImports);
  }, [props]);

  function handlenotificationForFollowersChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setNotificationForFollowers(value);
    // console.log(value);
  }
  function handlenotificationForUpvotesChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setNotificationForUpvotes(value);
    // console.log(value);
  }
  function handlenotificationForSyllabusImportsChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setNotificationForSyllabusImports(value);
    // console.log(value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isStatusUpdated) {
      const formData = new FormData();
      formData.append("notification_for_followers", notificationForFollowers);
      formData.append("notification_for_upvotes", notificationForUpvotes);
      formData.append(
        "notification_for_syllabus_imports",
        notificationForSyllabusImports
      );
      // console.log(formData);
      fetch(`${baseUrl}/api/user/user-notifications/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Box
        id="test2"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          p: "10px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="notification-following"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Notification for followers
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="notification-following"
              value={notificationForFollowers}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handlenotificationForFollowersChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handlenotificationForFollowersChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="notification-upvotes"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Notification for upvotes
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="notification-upvotes"
              value={notificationForUpvotes}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handlenotificationForUpvotesChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handlenotificationForUpvotesChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="notification-upvotes"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Notification for Syllabus Imports
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="notification-imports"
              value={notificationForSyllabusImports}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handlenotificationForSyllabusImportsChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handlenotificationForSyllabusImportsChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "60px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "#66CCFF",
              color: "black",
              boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
              border: "1px solid #dddddd",
              "&:hover": {
                border: "1px solid #dddddd",
                backgroundColor: "#87D7FF",
                boxShadow:
                  "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              Save Changes
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SettingsNotifications;
