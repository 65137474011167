import React, { useEffect, useState } from "react";
import VideosSearchResultCard from "./VideosSearchResultCard";
import { Grid, Box, Typography } from "@mui/material";
import DefaultVideoCard from "./DefaultVideoCard";
import CircularProgress from "@mui/material/CircularProgress";
import Cookie from "js-cookie";
import { tokenHandler } from "../services/tokenHandler";
import { useDispatch } from "react-redux";
import { baseUrl } from "../services/baseUrl";

const DefaultVideosComponent = (props) => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;
  const [scrollPosition, setScrollPosition] = useState("");
  const [scrollType, setScrollType] = useState("videos");
  // /?q=${props.searchQuery}
  const access_token = Cookie.get("access_token");
  const [retryCount, setRetryCount] = useState(0);
  //   const xyz = async () =>{
  //     let isMounted = true;
  //     const abortController = new AbortController();
  //     setIsLoading(true);

  //     try {
  //       const response = await fetch(
  //         `http://127.0.0.1:8000/api/posts/getdefaultfeedvideos/?offset=${offset}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${access_token}`,
  //           },
  //           signal: abortController.signal,
  //         }
  //       );
  //       const data = await response.json();
  //       if (isMounted) {
  //         console.log("videos saved", data);
  //         setVideos((prev) => [...prev, ...data]);
  //         setHasMore(data.length > 0);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setIsLoading(false);
  //     }

  //     // Clean up function
  //     return () => {
  //       isMounted = false;
  //       abortController.abort();
  //     };

  //   }
  //   useEffect(() => {
  //     const maxRetryAttempts = 3;
  //     let retryCount = 0;

  //     const fetchUserDetails = async () => {
  //       try {
  //         const response = xyz();

  //         if (!response.ok) {
  //           if (response.status === 401 && retryCount < maxRetryAttempts) {
  //             // Increment the retry count and retry the request
  //             retryCount++;
  //             await fetchUserDetails();
  //           } else {
  //             // Logout the user
  //             // Add code here to handle user logout, e.g., clear tokens, redirect to login page, etc.
  //             // Uncomment the following line when you've implemented the logout logic:
  //             logoutUser();
  //           }
  //         } else {
  //           const data = await response.json();
  //           setUserData(data);
  //           if (data.profile_pic !== null) {
  //             setProfilePic(data.profile_pic);
  //           }
  //           if (data.bio !== null) {
  //             setBio(data.bio);
  //           }
  //           setIsFollowing(data.is_following);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         setLoading(false);
  //       }

  // const cleanup = xyz();

  //   }, [offset]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const abortController = new AbortController();

  //   const fetchData = async () => {
  //     setIsLoading(true);

  //     try {
  //       const response = await fetch(
  //         `http://127.0.0.1:8000/api/posts/getdefaultfeedvideos/?offset=${offset}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           signal: abortController.signal,
  //         }
  //       );
  //       const data = await response.json();
  //       if (isMounted) {
  //         console.log("videos saved", data);
  //         setVideos((prev) => [...prev, ...data]);

  //         setHasMore(data.length > 0);

  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchData();
  //   return () => {
  //     isMounted = false;
  //     abortController.abort();
  //   };
  // }, [offset]);
  useEffect(() => {
    let isMounted = true; // To ensure the component is still mounted
    const abortController = new AbortController(); // Abort controller for canceling fetch

    const fetchData = async () => {
      setIsLoading(true); // Set loading state

      try {
        const response = await fetch(
          `${baseUrl}/api/posts/getdefaultfeedvideos/?offset=${offset}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            signal: abortController.signal, // Pass the abort signal to the fetch
          }
        );

        const data = await response.json();

        if (isMounted) {
          // console.log("videos saved", data);
          setVideos((prev) => [...prev, ...data]);
          setHasMore(data.length > 0);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch request was aborted"); // Handle abort error
        } else {
          console.error("Error fetching data:", error); // Handle other errors
        }
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Ensure no state updates if unmounted
      abortController.abort(); // Abort fetch when component unmounts or dependency changes
    };
  }, [offset]); // Re-run fetch when `offset` changes

  //         if(error.status === 401)
  //         {
  //           console.log("***************  inside 401 catch and called tokenHandler")
  //           tokenHandler();
  //           try{
  //             fetchData();
  //           }
  //           catch(error){
  // console.log(error)
  //           }
  //         }

  useEffect(() => {
    const handleInfiniteScroll = async () => {
      try {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight &&
          !isLoading &&
          hasMore
        ) {
          // if (hasMore) {
          setOffset((prev) => prev + 20);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [isLoading, hasMore]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleInfiniteScroll);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem(
      "videos_scrollPosition"
    );
    if (storedScrollPosition !== null) {
      const parsedScrollPosition = parseInt(storedScrollPosition, 10);
      setScrollPosition(parsedScrollPosition);
      // console.log("scroll position for default feed", parsedScrollPosition);
      // document.documentElement.scrollTop = scrollPosition;
      window.scrollTo(0, parseInt(scrollPosition));
    }
  }, [isLoading]);

  return (
    <>
      <Grid
        container
        spacing={1}
        display="flex"
        sx={{
          p: "5px",
          // backgroundColor: "cyan"
        }}
      >
        {/* {!isLoading ? ( */}
        {videos.map((video) => (
          <Grid
            key={video.video.id}
            item
            xl={2.4}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            sx={{
              // backgroundColor: "blue",
              // minHeight: {
              //   xl: "300px",
              // },
              height: {
                xs: "fit-content",
                sm: "fit-content",
                md: "fit-content",
                lg: "fit-content",
                // lg: "15vw",
              },
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {/* <div > */}
            <DefaultVideoCard
              videoInfo={video}
              scrollType={scrollType}
              scrollPosition={scrollPosition}
            />

            {/* </div> */}
          </Grid>
        ))}
        {isLoading ? (
          <Box sx={{ width: "99%" }}>
            <Box
              sx={{
                mt: "8px",
                width: "100%",
                p: "10px",
                borderRadius: "10px",
                ml: "10px",
                height: "100px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          ""
        )}
        {/* ) : (
          <Box
            sx={{
              mt: "8px",
              width: "100%",
              p: "10px",
              borderRadius: "10px",
              ml: "10px",
              height: "200px",
              // backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
          // <Box
          //   sx={{
          //     height: "calc(100vh-96px)",
          //     width: "100%",
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //   }}
          // >
          //   <CircularProgress />
          // </Box>
        )} */}
        {!hasMore ? (
          <Box
            sx={{
              mt: "8px",
              width: "100%",
              p: "10px",
              borderRadius: "10px",
              ml: "10px",
              height: "30px",
              // backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "0.7rem",
              }}
            >
              That's all we have. New content is being uploaded on studnerd
              everyday.
            </Typography>
            <Typography
              sx={{
                color: "grey",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                display: "flex",
                fontSize: "0.7rem",
              }}
            >
              {/* And our team is working round the clock to get you more quality
                content.  */}
              Thank you for choosing Studnerd!
            </Typography>
          </Box>
        ) : (
          ""
        )}
        {/* Render videos data here */}
      </Grid>
    </>
  );
};

export default DefaultVideosComponent;

// {props.searchType === "videos" &&
// typeof videoSearchResult !== "undefined"
//   ? videoSearchResult.map((video) => (
//       <div key={video.id}>
//         <Box sx={{ py: "3px", backgroundColor: "pink" }}>
//           <VideosSearchResultCard
//             id={video.id}
//             title={video.title}
//             owner={video.owner}
//             description={video.description}
//             thumbnail={video.thumbnail}
//             uploadedOn={video.uploaded_on}
//             views={video.views}
//             upvotes={video.upvotes}
//           />
//         </Box>
//       </div>
//     ))
//   : ""}
