import { Link, Navigate, NavLink, Outlet } from "react-router-dom";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Logo from "../images/logo.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { selectCurrentUserId, selectAccessToken } from "../features/authSlice";
import { getToken, removeToken } from "../services/CookieStorageService";
// import { getToken, removeToken } from "../services/LocalStorageService";
import { useDispatch, useSelector } from "react-redux";
import { unSetUserToken } from "../features/authSlice";
import { unSetUserInfo, setUserInfo } from "../features/userSlice";
// import { useGetLoggedUserQuery } from "../services/userAuthApi";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
// import { Autocomplete } from "@mui/material";
import Navbar2 from "./Navbar2";
import SearchResults from "./pages/SearchResults";
import { logout } from "../features/authSlice";
import jwt_decode from "jwt-decode";
import { useSearchParams } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Avatar from "@mui/material/Avatar";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import {
  setCurrentState,
  setCurrentSubject,
  setCurrentTab,
  setCurrentTopic,
  setDrawerState,
  setSelectedTopicQuery,
} from "../features/homeStateSlice";
import { setCurrentCourse } from "../features/treeSlice";
import { baseUrl } from "../services/baseUrl";
import Cookies from "js-cookie";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

// import { handleGoogleSignout } from "../services/handleGoogleSignout";

export default function PrimarySearchAppBar(props) {
  const { access_token } = getToken();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [notificationCount, setNotificationCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setsearchQuery] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);

  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState(null);
  const openNotifications = Boolean(anchorElNotifications);
  const handleClickNotifications = (event) => {
    setIsNotificationLoading(true);
    fetchNotifications();
    setAnchorElNotifications(event.currentTarget);
    setNotificationCount(0);
  };
  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/notifications/get-notifications`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("user_not_found");
        }
        throw new Error("An error occurred");
      }

      const data = await response.json();
      // setUserData(data);
      if (data.notifications !== null) {
        setNotifications(data.notifications);
      }
    } catch (error) {
      // console.log(error.code);
    } finally {
      setIsNotificationLoading(false);
    }
  };

  useEffect(() => {
    setNotificationCount(0);
    const fetchNotifCount = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/notifications/get-notifCnt`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("user_not_found");
          }
          throw new Error("An error occurred");
        }

        const data = await response.json();
        // setUserData(data);
        if (data.total_count !== null) {
          setNotificationCount(data.total_count);
        }
      } catch (error) {
        // console.log(error.code);
      }
    };
    try {
      fetchNotifCount();
    } catch (error) {
      // console.log(error.code);
    }
  }, []);

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  useEffect(() => {
    const filter = searchParams.get("filter");
    if (filter === null) {
      setFilterValue("");
    } else {
      setFilterValue(filter);
    }
    // console.log("filter is ", filter);
  }, [searchQuery, filterValue]);

  useEffect(() => {
    if (props.searchbarValue) {
      setsearchQuery(props.searchbarValue);
    }

    // if (props.filterValue) {
    //   setFilterValue(props.filterValue);
    // }
  }, [props.searchbarValue]);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.setItem("searchResult_scrollPosition", 0);

    const latestFilterValueFromUrl = searchParams.get("filter");
    setFilterValue(latestFilterValueFromUrl);
    if (searchQuery !== "") {
      if (
        latestFilterValueFromUrl === "" ||
        latestFilterValueFromUrl === "undefined" ||
        latestFilterValueFromUrl === null
      ) {
        navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
      } else {
        navigate(
          `/search?query=${encodeURIComponent(
            searchQuery
          )}&filter=${encodeURIComponent(latestFilterValueFromUrl)}`
        );
      }
    }
    // props.onSubmit(searchQuery);
  };

  const handleChange = (e) => {
    // console.log("this is navbar", e.target.value);
    e.preventDefault();
    setsearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   if (access_token === null) {
  //     navigate("/login");
  //   }
  // }, [access_token]);

  const handleLogout = () => {
    // console.log(access_token);
    axios.post(`${baseUrl}/api/user/logout/`, {
      access_token: Cookies.get("access_token"),
    });
    dispatch(logout());

    // dispatch(unSetUserToken({ access_token: null }));
    // dispatch(unSetUserInfo({ name: "", email: "" }));
    // handleGoogleSignout();
    removeToken();
    handleMenuClose();

    dispatch(
      setCurrentState({
        isStateSaved: false,
        selectedTab: "",
        selectedSubject: "",
        selectedTopic: "",
      })
    );
    // dispatch(setCurrentSubject({ isStateSaved: false, selectedSubject: "" }));
    // dispatch(setCurrentTab({ isStateSaved: false, selectedTab: "" }));
    // dispatch(setCurrentTopic({ isStateSaved: false, selectedTopic: "" }));
    dispatch(setDrawerState({ isStateSaved: false, isDrawerOpen: false }));
    dispatch(setCurrentCourse({ isCourseLoaded: false, course: null }));
    dispatch(
      setSelectedTopicQuery({ isStateSaved: false, selectedTopicQuery: "" })
    );

    navigate("/login");
  };

  // const { data, isSuccess } = useGetLoggedUserQuery(access_token);
  // let currentUserId = "12345";
  let currentUserId = useSelector(selectCurrentUserId);
  // console.log("this is the current user id", currentUserId);
  if (
    currentUserId === "undefined" ||
    typeof currentUserId === "undefined" ||
    currentUserId == null
  ) {
    if (
      typeof access_token !== "undefined" &&
      access_token !== null &&
      access_token !== "undefined"
    ) {
      const data = jwt_decode(access_token);
      // console.log("data is this ", data);
      currentUserId = data.user_id;
    }
  }

  // console.log("in navbar ", currentUserId, user);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  // const handleSearchX = (value) => {
  //   // const { value } = event.target;
  //   setSearchValue(value);
  //   // console.log("searching for:", searchValue);
  //   // make API request for search results
  // };

  // const handleInputChange = (event) => {
  //   setSearchValue(event.target.value);
  // };
  const menuId = "primary-search-account-menu";
  const menuId2 = "notifications-icon";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        component={NavLink}
        to={`/profile/${currentUserId}`}
        onClick={handleMenuClose}
        sx={{
          fontSize: "0.7rem",
        }}
      >
        <Avatar />{" "}
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontSize: "0.8rem",
          }}
        >
          Profile
        </Typography>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>
        <Avatar /> My account
      </MenuItem> */}
      <Divider />
      {/* <MenuItem onClick={handleMenuClose}>
        <ListItemIcon>
          <PersonAdd fontSize="small" />
        </ListItemIcon>
        Add another account
      </MenuItem> */}
      <MenuItem
        component={NavLink}
        to="/account/settings"
        onClick={handleMenuClose}
        sx={{
          fontSize: "0.6rem",
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontSize: "0.8rem",
          }}
        >
          Settings
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={NavLink}
        to="/help"
        sx={{
          fontSize: "0.6rem",
        }}
      >
        <ListItemIcon>
          <HelpIcon fontSize="small" />
        </ListItemIcon>
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontSize: "0.8rem",
          }}
        >
          Help
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={NavLink}
        to="/about-us"
        sx={{
          fontSize: "0.6rem",
        }}
      >
        <ListItemIcon>
          <InfoIcon fontSize="small" />
        </ListItemIcon>
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontSize: "0.8rem",
          }}
        >
          About us
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleLogout}
        sx={{
          fontSize: "0.6rem",
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontSize: "0.8rem",
          }}
        >
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenuLogin = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={NavLink} to="/login">
        <IconButton size="large" color="inherit">
          <AddCircleIcon />
        </IconButton>
        <p>Login</p>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={NavLink} to="/create">
        <IconButton size="large" color="inherit">
          <AddCircleIcon />
        </IconButton>
        <p>Upload</p>
      </MenuItem>
      <MenuItem onClick={handleClickNotifications}>
        <IconButton
          size="large"
          aria-label="more notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExpandMoreIcon />
        </IconButton>
        <p>More</p>
      </MenuItem>
    </Menu>
  );

  const handleLogoClick = () => {
    // console.log("logo clicked");
    // console.log(window.location.pathname);
    if (window.location.pathname === "/home") {
      // If already on the home page, perform the desired action
      // For example, you can execute additional logic or refresh the page
      window.location.reload();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: "black" }}>
        {/* <AppBar position="relative" sx={{ zIndex: props.zIndex }}> */}
        <Toolbar variant="dense" sx={{ backgroundColor: "black" }}>
          <Box
            component={NavLink}
            to="/home"
            onClick={handleLogoClick}
            sx={{
              display: "flex",
              backgroundColor: "black",
              height: "32px",
              width: "144px",
              alignItems: "center",
              justifyContent: "center",
              display: {
                xs: isSearchFocused ? "none" : "",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <img src={Logo} height="100%" />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputBase
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              sx={{
                // width: "35%",
                width: { xs: "100%", sm: "35%" },
                border: "0.5px solid #000",
                borderRadius: "15px",
                pl: "15px",
                pt: "2px",
                fontFamily: "Montserrat",
                backgroundColor: "#363636",
                color: "#AAAAAA",
                fontSize: "0.8rem",
              }}
              value={searchQuery}
              // onChange={(e) => setsearchQuery(e.target.value)}
              onChange={handleChange}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     e.preventDefault();
              //     props.onButtonClick(searchQuery);
              //   }
              // }}
            />
            <Button
              size="small"
              variant="contained"
              onClick={handleSubmit}
              // onClick={(e) => {
              //   e.preventDefault();
              //   props.onButtonClick(searchQuery);
              // }}
              sx={{
                height: "2rem",
                display: "flex",
                ml: "5px",
                borderRadius: "15px",

                // color: "white",
                // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #000000",
                backgroundColor: "#363636",
                "&:hover": {
                  backgroundColor: "#4A4A4A",
                },
              }}
            >
              <SearchIcon sx={{ mx: "2px" }} />
            </Button>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              //   backgroundColor: "pink",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          
            <InputBase
              sx={{
                width: "30%",
                // backgroundColor: "yellow",
                border: "0.5px solid #66CCFF",
                borderRadius: "15px",
                pl: "10px",
                fontFamily: "sans-serif",
                backgroundColor: "white",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Box> */}
          <Box sx={{ flexGrow: 1 }} />
          {access_token ? (
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                component={NavLink}
                to="/create"
                size="large"
                color="inherit"
              >
                <AddCircleIcon />
              </IconButton>

              <IconButton
                size="large"
                aria-label="more"
                id="long-button"
                aria-controls={openNotifications ? "long-menu" : undefined}
                aria-expanded={openNotifications ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickNotifications}
              >
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsIcon sx={{ color: "white" }} />
                </Badge>
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorElNotifications}
                open={openNotifications}
                onClose={handleCloseNotifications}
                PaperProps={{
                  style: {
                    maxHeight: "500px",
                    maxWidth: "250px",
                  },
                }}
                // slotProps={{
                //   paper: {
                //     style: {
                //       maxHeight: "20px",
                //       maxWidth: "10px",
                //       // width: "20ch",
                //     },
                //   },
                // }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {notifications.map((notification) => (
                  <MenuItem
                    component={Link}
                    to={`/profile/${notification.source_url_id}`}
                    key={notification.id}
                    // selected={option === "Pyxis"}
                    onClick={handleCloseNotifications}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "8px 16px",
                      margin: "4px 0",
                      fontSize: "14px",
                      fontFamily: "Arial, sans-serif",
                      lineHeight: 0.5,
                      "&:hover": {
                        backgroundColor: "#f0f0f0", // lighter background on hover
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        // fontWeight: "bold",
                        fontSize: "0.8rem",
                        // marginBottom: "4px", // Space between name and time
                      }}
                    >
                      <strong> {notification.source_name}</strong> followed you
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {dayjs(notification.created_at).fromNow()}
                    </Typography>
                  </MenuItem>
                ))}

                {isNotificationLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      heigh: "1rem",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <div></div>
                )}
              </Menu>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                width: "180px",
                alignItems: "center",
                justifyContent: "center",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Button
                component={NavLink}
                to="/login"
                variant="contained"
                sx={{
                  height: "60%",
                  backgroundColor:
                    "linear-gradient(146deg, rgba(48,198,255,1) 0%, rgba(0,161,255,1) 100%)",
                }}
              >
                Login
              </Button>
            </Box>
          )}

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {access_token ? (
        <div>
          {renderMobileMenu}
          {renderMenu}
        </div>
      ) : (
        <div>{renderMobileMenuLogin}</div>
      )}
    </Box>
  );
}
