import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const SettingsHelp = () => {
  return (
    <>
      <Box
        id="test2"
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          p: "8px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <Box sx={{ width: "100%", my: "5px" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily:
                "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
            }}
          >
            Help And Support
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            width: "100%",
            my: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ my: "10px" }}>
            <Link to="/help" style={{ textDecoration: "none", my: "5px" }}>
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  // color: "GrayText", //commented because on the page it doesnt look like a link so clickable item
                }}
              >
                Help Center and FAQs
              </Typography>
            </Link>
          </Box>
          {/* <Box sx={{ my: "10px" }}>
            <Link to="/FAQs" style={{ textDecoration: "none", my: "5px" }}>
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  // color: "GrayText",
                }}
              >
                FAQs
              </Typography>
            </Link>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default SettingsHelp;
