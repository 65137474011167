import Cookies from "js-cookie";

const storeToken = (value) => {
  if (value) {
    const { access, refresh } = value;
    Cookies.set("access_token", access, {
      expires: 7,
      secure: true,
      sameSite: "none",
    });
    Cookies.set("refresh_token", refresh, {
      expires: 30,
      secure: true,
      sameSite: "none",
    });
  }
};

const getToken = () => {
  let access_token = Cookies.get("access_token");
  let refresh_token = Cookies.get("refresh_token");
  return { access_token, refresh_token };
};

const removeToken = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
};

export { storeToken, getToken, removeToken };
