import { Button, CssBaseline } from "@mui/material";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import FeedIcon from "@mui/icons-material/Feed";
import IconButton from "@mui/material/IconButton";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "./Navbar";
import Logo from "../images/logo.png";
import courseDefault from "../images/courseDefault.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchResults from "./pages/SearchResults";
import {
  selectIsCourseLoaded,
  selectCurrentCourse,
} from "../features/treeSlice";
import { getToken, removeToken } from "../services/CookieStorageService";
// import { getToken, removeToken } from "../services/LocalStorageService";
import { setCurrentCourse } from "../features/treeSlice";
import {
  selectCurrentTab,
  selectCurrentSubject,
  setCurrentSubject,
  setCurrentTab,
  setCurrentState,
  setCurrentTopic,
  setDrawerState,
  selectCurrentDrawerState,
  selectCurrentTopic,
  // selectCurrentScrollPosition,
  selectCurrentSelectedTopicQuery,
  setSelectedTopicQuery,
  // setCurrentScrollPosition,
} from "../features/homeStateSlice";
import ListSubheader from "@mui/material/ListSubheader";
import { logout } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../services/baseUrl";

// import { AuthInitializer } from "../features/InitializeAction";

const drawerWidth = 310;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: `calc(${drawerWidth}px + ${theme.spacing(3)})`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function NavbarWithSYllabus() {
  const dispatch = useDispatch();
  const [treeData, setTreeData] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseID, setCourseID] = useState("");
  const [value, setValue] = useState(0);
  const [defaultSubject, setDefaultSubject] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [treeElementSelected, setTreeElementSelected] = useState("");
  const [tabSelected, setTabSelected] = useState("videos");
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [menudata, setMenudata] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { access_token, refresh_token } = getToken();
  const [isCoursePresent, setisCourseLoaded] = useState(false);
  const handleDrawer = (value) => {
    // console.log(value);
    dispatch(setDrawerState({ isStateSaved: true, isDrawerOpen: value }));
    setOpen(value);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isCourseStoredInRedux = useSelector(selectIsCourseLoaded);
  const LoadedCourse = useSelector(selectCurrentCourse);
  const LoadedTab = useSelector(selectCurrentTab);
  const LoadedSubject = useSelector(selectCurrentSubject);
  const LoadedDrawerState = useSelector(selectCurrentDrawerState);
  const LoadedTopicId = useSelector(selectCurrentTopic);
  // const currentScrollPosition = useSelector(selectCurrentScrollPosition);
  const selectedTopicQuery = useSelector(selectCurrentSelectedTopicQuery);
  const [topicSelectedName, setTopicSelectedName] = useState("");
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (LoadedTopicId !== "") {
      setTreeElementSelected(LoadedTopicId);
    }
    if (LoadedTab !== "") {
      setTabSelected(LoadedTab);
    }
    if (LoadedSubject !== "") {
      setSelectedSubject(LoadedSubject);
      // setOpen(true);
    }
    if (LoadedDrawerState !== null) {
      setOpen(LoadedDrawerState);
    }
    if (selectedTopicQuery !== "") {
      setTopicSelectedName(selectedTopicQuery);
    }
    // if (currentScrollPosition !== 0) {
    //   setScrollPosition(currentScrollPosition);
    // }
  }, [LoadedTab, LoadedSubject, LoadedDrawerState, selectedTopicQuery]);

  // useEffect(() => {
  //   document.documentElement.scrollTop = scrollPosition;
  // }, [scrollPosition]);

  // useEffect(() => {
  //   console.log("IN THE USE EFFECT OF THE APP.JS FILE ");
  //   try {
  //     const isAuthenticated = dispatch(AuthInitializer());
  //     console.log(
  //       "This is is authenticated variable in app js useEffect",
  //       isAuthenticated
  //     );
  //     setIsAuthenticated(isAuthenticated);
  //   } catch (error) {
  //     // console.log("this is error message xyz", error.message);
  //     // if (error.message === "Invalid token specified") {
  //     //   dispatch(logout());
  //     //   removeToken();
  //     //   navigate("/login");
  //     // }
  //   }
  // }, [dispatch, isAuthenticated]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      access_token === null ||
      access_token === undefined ||
      access_token === "undefined"
    ) {
      // removeToken();
      // dispatch(logout());
      dispatch(
        setCurrentState({
          isStateSaved: false,
          selectedTab: "",
          selectedSubject: "",
          selectedTopic: "",
        })
      );
      dispatch(setCurrentSubject({ isStateSaved: false, selectedSubject: "" }));
      dispatch(setCurrentTab({ isStateSaved: false, selectedTab: "" }));
      dispatch(setCurrentTopic({ isStateSaved: false, selectedTopic: "" }));
      dispatch(setDrawerState({ isStateSaved: false, isDrawerOpen: false }));
      dispatch(setCurrentCourse({ isCourseLoaded: false, course: null }));
      dispatch(
        setSelectedTopicQuery({ isStateSaved: false, selectedTopicQuery: "" })
      );

      navigate("/login");
    }
  }, [access_token]);

  useEffect(() => {
    // if (isAuthenticated) {

    let isMounted = true;
    if (isCourseStoredInRedux && LoadedCourse !== null) {
      setTreeData(LoadedCourse);
      setCourseName(LoadedCourse[0].name);
      setCourseID(LoadedCourse[0].id);
      setisCourseLoaded(true);
    } else {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${baseUrl}/api/syllabus/check-and-get/`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
          const data = await response.json();
          // console.log(data);
          if (isMounted) {
            setTreeData(data.data);
            if (data.data[0].name) {
              setCourseName(data.data[0].name);
              setCourseID(data.data[0].id);
            }
            // console.log(data.data);
            // console.log(data.data.length);
            let isCoursePresent;
            if (data.data.length === 0) {
              setisCourseLoaded(false);
              isCoursePresent = false;
            } else {
              setisCourseLoaded(true);
              isCoursePresent = true;
            }
            // console.log("line 151 navbar2", isCoursePresent);
            dispatch(
              setCurrentCourse({
                isCourseLoaded: isCoursePresent,
                course: data.data,
              })
            );
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
    return () => {
      isMounted = false;
    };
    // }
  }, [access_token, isCourseStoredInRedux, LoadedCourse]);

  useEffect(() => {
    // if (isAuthenticated) {
    if (
      treeData !== null &&
      treeData.length !== 0 &&
      selectedSubject === "" &&
      LoadedSubject === ""
    ) {
      setSelectedSubject(treeData[0].subjects[0].id);
      // setDefaultSubject(treeData[0].subjects[0].id);
    }
    // }
  }, [treeData, LoadedSubject]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubjectChange = (event) => {
    setSelectedSubject(event.target.value);
    dispatch(
      setCurrentSubject({
        isStateSaved: true,
        selectedSubject: event.target.value,
      })
    );
  };

  const handleClick = (value, id) => {
    setTopicSelectedName(value);
    setTreeElementSelected(id);
    dispatch(setCurrentTopic({ isStateSaved: true, selectedTopic: id }));
    dispatch(
      setSelectedTopicQuery({ isStateSaved: true, selectedTopicQuery: value })
    );
    sessionStorage.setItem("feed_scrollPosition", 0);
    sessionStorage.setItem("videos_scrollPosition", 0);
    sessionStorage.setItem("notes_scrollPosition", 0);
    // dispatch(
    //   setSelectedTopicQuery({ isStateSaved: true, selectedTopicQuery: value })
    // );
  };

  const handleTabClick = (event, value) => {
    const currentPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem(`${tabSelected}_scrollPosition`, currentPosition);
    setTabSelected(value);

    dispatch(setCurrentTab({ isStateSaved: true, selectedTab: value }));
  };

  // useEffect(() => {
  //   const handleScrollValueUpdate = async () => {
  //     try {
  //       // console.log(document.documentElement.scrollTop);
  //       // const temp = document.documentElement.scrollTop;

  //       dispatch(
  //         setCurrentScrollPosition({
  //           isStateSaved: true,
  //           currentScrollPosition: document.documentElement.scrollTop,
  //         })
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScrollValueUpdate);
  // }, []);

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "block" }}>
        {/* <Navbar zIndex={theme.zIndex.drawer + 1} sx={{ position: "fixed" }} /> */}
        <AppBar
          position="fixed"
          // position="relative"
          elevation={2}
          sx={{
            pt: "48px",
            backgroundColor: "white",
            color: "black",
            zIndex: 1,
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              display: "flex",
              // maxHeight: "2.4rem", minHeight: "2.4rem"
            }}
          >
            <Grid container sx={{ display: "flex" }}>
              <Grid item lg={4} md={4} sm={4} xs={6}>
                <Box // add GRID HERE SO THAT WE CAN CONTROL THE SIZE OF THESE ELEMENTS
                  sx={{
                    display: "flex",
                    // backgroundColor: "orange",
                    minWidth: "100px",
                    height: "100%",
                    // flexGrow: "1",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      handleDrawer(!open);
                    }}
                    edge="start"
                    sx={{
                      transition: "background-color 0.5s", // Define the transition property
                      "&.pulsing": {
                        backgroundColor: "red", // Color 1
                      },
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Button
                    component={NavLink}
                    to="/import"
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      ml: "7px",
                      transition: "0.4s",
                      textAlign: "center",
                      textTransform: "none",
                      backgroundColor: "#D7FFCE",
                      // backgroundColor: "#66FFA3",
                      // width: "5rem",
                      height: "1.8rem",
                      color: "#096409",
                      boxShadow: "none",
                      // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
                      // border: "1px solid #009E29",
                      "&:hover": {
                        boxShadow: "none",

                        backgroundColor: "#E7FFE2",
                        // boxShadow:
                        //   "inset -2px -2px 2px #DFF2FC,inset 2px 2px 2px #777777",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        // fontFamily: "monospace",
                        fontFamily: "Proxima Nova,Verdana,Avenir Next",
                      }}
                    >
                      Import
                    </Typography>
                  </Button>

                  <Button
                    component={NavLink}
                    to="/create-syllabus"
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      ml: "7px",
                      transition: "0.4s",
                      textAlign: "center",
                      textTransform: "none",
                      backgroundColor: "#C5ECFF",
                      // backgroundColor: "#66FFA3",
                      // width: "8rem",
                      height: "1.8rem",
                      color: "#52829A",
                      boxShadow: "none",
                      // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
                      // border: "1px solid #009E29",
                      "&:hover": {
                        boxShadow: "none",

                        backgroundColor: "#D7F1FE",
                        // boxShadow:
                        //   "inset -2px -2px 2px #DFF2FC,inset 2px 2px 2px #777777",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        // fontFamily: "monospace",
                        fontFamily: "Proxima Nova,Verdana,Avenir Next",
                      }}
                    >
                      Create Syllabus
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={4} md={6} sm={8} xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    // backgroundColor: "green",
                    height: "100%",
                    // flexGrow: "1",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabClick}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{
                      "& .MuiTab-root.Mui-selected": {
                        color: "black",
                      },
                    }}
                    TabIndicatorProps={{
                      style: { backgroundColor: "black" },
                    }}
                    // aria-label="icon label tabs example"
                  >
                    <Tab
                      disableRipple
                      // icon={<FeedIcon />}
                      label="Feed"
                      value="feed"
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontFamily: "Montserrat",
                        mx: "8px",
                      }}
                    />
                    <Tab
                      disableRipple
                      // icon={<OndemandVideoIcon />}
                      label="Videos"
                      value="videos"
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontFamily: "Montserrat",
                        mx: "8px",
                      }}
                    />
                    <Tab
                      disableRipple
                      // icon={<MenuBookIcon />}
                      label="Notes"
                      value="notes"
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontFamily: "Montserrat",
                        mx: "8px",
                      }}
                    />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item lg={4} md={2} sm={0} xs={0}>
                <Box
                  sx={{
                    // display: "flex",
                    // backgroundColor: "red",
                    height: "100%",
                    // flexGrow: "1",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            zIndex: theme.zIndex.drawer - 1,
            width: "12%",
            minWidth: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "12%",
              minWidth: drawerWidth,
              boxSizing: "border-box",
              borderWidth: 0,
              border: "1px solid black",
              boxShadow: 0,
            },
          }}
          style={{ backgroundColor: "#F8F8F8" }}
          variant="persistent"
          open={open}
        >
          <DrawerHeader
            sx={{
              p: 0,
              height: "110px",
              display: "block",
              // backgroundColor: "red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                backgroundColor: "black",
                minHeight: "48px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Logo} height="32px" />
            </Box>
            <Box
              sx={{
                height: "51px",
                pl: "20px",
                display: "flex",
                // backgroundColor: "#88FCFF",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {
                    handleDrawer(!open);
                  }}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "pink",
                }}
              >
                {courseName ? (
                  <Link
                    to={`/course/${courseID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{ fontSize: "0.8rem", fontFamily: "Montserrat" }}
                    >
                      {courseName}
                    </Typography>
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </DrawerHeader>
          <Divider />
          {/* <Box
            sx={{
              backgroundColor: "yellow",
              // height: "calc(100vh-112px)",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            snbdjhsbd xsx xzczx zxczxc zcx
            <Box sx={{ backgroundColor: "pink" }}>als</Box>
          
            <Box sx={{ backgroundColor: "pink" }}>als</Box>
            <Box sx={{ backgroundColor: "pink" }}>als</Box>
            <Box sx={{ backgroundColor: "pink" }}>als</Box>
          </Box> */}
          {isCourseStoredInRedux ? (
            <Box
              sx={{
                p: "4px",
                height: "100%",
                overflowY: "auto",
                // backgroundColor: "#EBF9FF",
                // backgroundColor: "#002436",
              }}
            >
              {treeData.map((course) => (
                <div key={course.id}>
                  <Select
                    size="small"
                    value={selectedSubject || defaultSubject}
                    onChange={handleSubjectChange}
                    sx={{
                      width: "100%",
                      borderRadius: "8px",
                      // backgroundColor: "#D6F1FF",
                      backgroundColor: "#F3F3F3",
                      mb: "3px",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                      // display: "-webkit-box",
                      // WebkitLineClamp: "1",
                      // WebkitBoxOrient: "vertical",
                    }}
                  >
                    {course.subjects.map((subject) => (
                      <MenuItem
                        key={subject.id}
                        value={subject.id}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ fontSize: "0.8rem", fontFamily: "Segoe UI" }}
                        >
                          {subject.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ))}
              <Box
                sx={{
                  // overflowY: "scroll",
                  // height: "100%",
                  // backgroundColor: "pink",
                  minHeight: `calc(100vh - 210px)`,
                  // minHeight: `800px`,
                  p: "3px",
                }}
              >
                <List
                  sx={
                    {
                      // backgroundColor: "white",
                      // p: 0,
                    }
                  }
                >
                  {treeData.map((course) => (
                    <div key={course.id}>
                      {course.subjects.map((subject) => {
                        {
                          // subject.id.toString() == selectedSubject && (
                          if (subject.id === selectedSubject) {
                            return (
                              <div key={subject.id}>
                                {subject.topics.map((topic) => (
                                  <div key={topic.id}>
                                    <ListItem
                                      title={topic.name}
                                      disablePadding
                                      onClick={() =>
                                        handleClick(topic.name, topic.id)
                                      }
                                      sx={{
                                        my: "2px",
                                        // p: "0px",
                                        backgroundColor: "white",
                                        // backgroundColor: "#D6F1FF",
                                        // backgroundColor: "#99DDFF",
                                        border: "1px solid #004C72",
                                        borderRadius: "6px",
                                        height: "1.6rem",
                                      }}
                                    >
                                      <ListItemButton
                                        selected={topic.id === LoadedTopicId}
                                      >
                                        <Typography
                                          sx={{
                                            // fontSize: "14px",
                                            fontSize: "0.8rem",
                                            fontFamily: "Montserrat",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                          }}
                                        >
                                          {topic.name}
                                        </Typography>
                                      </ListItemButton>
                                    </ListItem>
                                    {topic.subtopics.map((subtopic) => (
                                      <div key={subtopic.id}>
                                        <ListItem
                                          title={subtopic.name}
                                          disablePadding
                                          onClick={() =>
                                            handleClick(
                                              topic.name + " " + subtopic.name,
                                              subtopic.id
                                            )
                                          }
                                          sx={{
                                            height: "50%",
                                            ml: "10px",
                                            my: "2px",
                                            width: "auto",
                                            // color: "white",
                                            backgroundColor: "white",
                                            // backgroundColor: "#E7F7FF",
                                            // backgroundColor: "#99DDFF",
                                            border: "1px solid #004C72",
                                            borderRadius: "6px",
                                            // background: "#B6E5FD",
                                            height: "1.6rem",
                                          }}
                                        >
                                          <ListItemButton
                                            selected={
                                              subtopic.id === LoadedTopicId
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                // fontSize: "12px",
                                                fontSize: "0.7rem",
                                                fontFamily: "Montserrat",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "1",
                                                WebkitBoxOrient: "vertical",
                                              }}
                                            >
                                              {subtopic.name}
                                            </Typography>
                                          </ListItemButton>
                                        </ListItem>

                                        {subtopic.subsubtopics.map(
                                          (subsubtopic) => (
                                            <div key={subsubtopic.id}>
                                              <ListItem
                                                title={subsubtopic.name}
                                                disablePadding
                                                onClick={() =>
                                                  handleClick(
                                                    topic.name +
                                                      " " +
                                                      subtopic.name +
                                                      " " +
                                                      subsubtopic.name,
                                                    subsubtopic.id
                                                  )
                                                }
                                                sx={{
                                                  height: "50%",
                                                  ml: "20px",
                                                  my: "2px",
                                                  width: "auto",

                                                  // backgroundColor: "#D6F1FF",
                                                  // backgroundColor: "#99DDFF",
                                                  border: "1px solid #004C72",
                                                  borderRadius: "6px",
                                                  // background: "#B6E5FD",
                                                  height: "1.6rem",
                                                }}
                                              >
                                                <ListItemButton
                                                  selected={
                                                    subsubtopic.id ===
                                                    LoadedTopicId
                                                  }
                                                >
                                                  <Typography
                                                    sx={{
                                                      // fontSize: "12px",
                                                      fontSize: "0.7rem",
                                                      fontFamily: "Montserrat",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      display: "-webkit-box",
                                                      WebkitLineClamp: "1",
                                                      WebkitBoxOrient:
                                                        "vertical",
                                                    }}
                                                  >
                                                    {subsubtopic.name}
                                                  </Typography>
                                                </ListItemButton>
                                              </ListItem>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  ))}
                </List>
              </Box>
              <Box
                sx={{
                  p: "5px",
                }}
              >
                <Link
                  component={NavLink}
                  to="/about-us"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      ml: "10px",
                      fontSize: "0.7rem",
                      fontFamily: "Montserrat",
                      display: "inline-block",
                    }}
                  >
                    About Us
                  </Typography>
                </Link>{" "}
                <Link
                  component={NavLink}
                  to="/terms-of-service"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      ml: "10px",
                      fontSize: "0.7rem",
                      fontFamily: "Montserrat",
                      display: "inline-block",
                    }}
                  >
                    Terms of Service
                  </Typography>
                </Link>{" "}
                <Link
                  component={NavLink}
                  to="/privacy-policy"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      ml: "10px",
                      fontSize: "0.7rem",
                      fontFamily: "Montserrat",
                      display: "inline-block",
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>{" "}
                <Link
                  component={NavLink}
                  to="/blogs"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      ml: "10px",
                      fontSize: "0.7rem",
                      fontFamily: "Montserrat",
                      display: "inline-block",
                    }}
                  >
                    Blogs
                  </Typography>
                </Link>{" "}
                <Link
                  component={NavLink}
                  to="/help"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      ml: "10px",
                      fontSize: "0.7rem",
                      fontFamily: "Montserrat",
                      display: "inline-block",
                    }}
                  >
                    Help
                  </Typography>
                </Link>{" "}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={courseDefault} height="150px" />
              <Typography sx={{ fontSize: "0.8rem", fontFamily: "Montserrat" }}>
                Nothing to see here :/
              </Typography>
              <Typography sx={{ fontSize: "0.8rem", fontFamily: "Montserrat" }}>
                <Link component={NavLink} to="/import">
                  Import
                </Link>{" "}
                to get started
              </Typography>
            </Box>
          )}
          <Divider />
        </Drawer>
        <Main
          sx={{
            display: "contents",
            // position: "fixed",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
        >
          <SearchResults
            isCourseLoaded={isCoursePresent}
            searchQuery={topicSelectedName}
            searchType={tabSelected}
            // scrollPosition={currentScrollPosition}
          ></SearchResults>
        </Main>
      </Box>
    </>
  );
}

// const drawerWidth = 240;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: "flex-end",
// }));

// const Navbar2 = () => {
//   const [treeData, setTreeData] = useState([]);
//   const [courseName, setCourseName] = useState("");
//   const [value, setValue] = useState(0);
//   const [selectedSubject, setSelectedSubject] = useState("");

//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [menudata, setMenudata] = useState("Home");

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };
//   useEffect(() => {
//     axios
//       .get(
//         `http://127.0.0.1:8000/api/syllabus/course/646683fb-21f3-4b87-90f0-5e64233ced7d`
//       )
//       .then((res) => {
//         setTreeData(res.data);
//         // console.log(res.data);
//         setCourseName(res.data[0].name);
//       });
//   }, []);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleSubjectChange = (event) => {
//     setSelectedSubject(event.target.value);
//   };

//   return (
//     <>
//       <Box sx={{ flexGrow: 1 }}>
//         <CssBaseline></CssBaseline>
//         <AppBar
//           position="static"
//           elevation={4}
//           sx={{
//             backgroundColor: "#ffffff",
//             color: "black",
//             // zIndex: theme.zIndex.drawer + 1,
//           }}
//         >
//           <Toolbar
//             variant="dense"
//             sx={{ backgroundColor: "white", zIndex: theme.zIndex.drawer + 2 }}
//           >
//             <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               onClick={() => {
//                 setOpen(!open);
//               }}
//               edge="start"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Box
//               sx={{
//                 display: "flex",
//                 // backgroundColor: "red",
//                 height: "100%",
//                 width: "33%",
//                 alignItems: "center",
//                 justifyContent: "left",
//               }}
//             >
//               <Stack direction="row" spacing={2}>
//                 <Button
//                   variant="contained"
//                   component={NavLink}
//                   to="/import"
//                   sx={{
//                     background:
//                       "linear-gradient(146deg, rgba(100,226,158,1) 0%, rgba(25,228,79,1) 100%)",
//                     // background: "rgb(100,226,158)",
//                     color: "green",
//                     textTransform: "none",
//                     borderRadius: "4px",
//                     fontFamily: "Segoe UI",
//                     "&:hover": {
//                       background: "#c0ffdd",
//                     },
//                   }}
//                 >
//                   Import
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   component={NavLink}
//                   to="/create-syllabus"
//                   sx={{
//                     textTransform: "none",
//                     fontFamily: "Segoe UI",
//                     "&:hover": {
//                       background: "lightblue",
//                     },
//                   }}
//                 >
//                   Create Syllabus
//                 </Button>
//               </Stack>
//             </Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 // backgroundColor: "green",
//                 height: "100%",
//                 width: "33%",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Stack
//                 direction="row"
//                 spacing={{ xs: 1, sm: 2, md: 4, lg: 10 }}
//                 sx={{ alignItems: "center" }}
//               >
//                 <Button
//                   component={NavLink}
//                   to="/home"
//                   style={({ isActive }) => {
//                     return { backgroundColor: isActive ? "#dcdedd" : "" };
//                   }}
//                   sx={{
//                     height: "70%",
//                     color: "black",
//                   }}
//                 >
//                   <FeedIcon />
//                 </Button>
//                 <Button
//                   component={NavLink}
//                   to="/videos"
//                   style={({ isActive }) => {
//                     return { backgroundColor: isActive ? "#dcdedd" : "" };
//                   }}
//                   sx={{
//                     height: "70%",
//                     color: "black",
//                   }}
//                 >
//                   <OndemandVideoIcon />
//                 </Button>
//                 <Button
//                   component={NavLink}
//                   to="/notes"
//                   style={({ isActive }) => {
//                     return { backgroundColor: isActive ? "#dcdedd" : "" };
//                   }}
//                   sx={{
//                     height: "70%",
//                     color: "black",
//                   }}
//                 >
//                   <MenuBookIcon />
//                 </Button>
//               </Stack>
//             </Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 // backgroundColor: "blue",
//                 height: "100%",
//                 width: "33%",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             ></Box>
//           </Toolbar>
//         </AppBar>
//         <Drawer
//           sx={{
//             width: drawerWidth,
//             flexShrink: 0,
//             "& .MuiDrawer-paper": {
//               width: drawerWidth,
//               boxSizing: "border-box",
//             },
//           }}
//           variant="persistent"
//           open={open}
//         >
//           <DrawerHeader>
//             <IconButton onClick={handleDrawerClose}>
//               {/* {theme.direction === "ltr" ? (
//                 <ChevronLeftIcon />
//               ) : (
//                 <ChevronRightIcon />
//               )} */}
//             </IconButton>
//           </DrawerHeader>
//           <Divider />
//           <Typography>{courseName}</Typography>
//           xx
//           {treeData.map((course) => (
//             <div key={course.id}>
//               <Select
//                 value={selectedSubject}
//                 onChange={handleSubjectChange}
//                 sx={{ width: "100%" }}
//               >
//                 {course.subjects.map((subject) => (
//                   <MenuItem key={subject.id} value={subject.id}>
//                     {subject.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </div>
//           ))}
//           <List>
//             {treeData.map((course) => (
//               <div key={course.id}>
//                 {course.subjects.map((subject) => {
//                   {
//                     // subject.id.toString() == selectedSubject && (
//                     if (subject.id === selectedSubject) {
//                       return (
//                         <div key={subject.id}>
//                           {subject.topics.map((topic) => (
//                             <div key={topic.id}>
//                               <ListItem disablePadding>
//                                 <ListItemButton>
//                                   {/* <ListItemIcon> */}
//                                   <Typography>{topic.name}</Typography>
//                                   {/* </ListItemIcon> */}
//                                 </ListItemButton>
//                               </ListItem>
//                               {topic.subtopics.map((subtopic) => (
//                                 <div key={subtopic.id}>
//                                   <ListItem
//                                     disablePadding
//                                     sx={{ height: "50%" }}
//                                   >
//                                     <ListItemButton>
//                                       {/* <ListItemIcon> */}
//                                       <Typography sx={{ fontSize: "0.85em" }}>
//                                         {subtopic.name}
//                                       </Typography>
//                                       {/* </ListItemIcon> */}
//                                     </ListItemButton>
//                                   </ListItem>

//                                   {subtopic.subsubtopics.map((subsubtopic) => (
//                                     <div key={subsubtopic.id}>
//                                       <ListItem
//                                         disablePadding
//                                         sx={{ height: "50%" }}
//                                       >
//                                         <ListItemButton>
//                                           {/* <ListItemIcon> */}
//                                           <Typography
//                                             sx={{ fontSize: "0.7em" }}
//                                           >
//                                             {subsubtopic.name}
//                                           </Typography>
//                                           {/* </ListItemIcon> */}
//                                         </ListItemButton>
//                                       </ListItem>
//                                     </div>
//                                   ))}
//                                 </div>
//                               ))}
//                             </div>
//                           ))}
//                         </div>
//                       );
//                     }
//                   }
//                 })}
//               </div>
//             ))}
//           </List>
//           <Divider />
//         </Drawer>
//       </Box>
//     </>
//   );
// };

// export default Navbar2;
