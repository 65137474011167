import React from "react";
import { Box, Grid, Typography, List, ListItem } from "@mui/material";
import Logo from "../images/logo.png";
import linkedinLogo from "../staticfiles/svg/linkedin-white-logo.svg";
import facebookLogo from "../staticfiles/svg/facebook-white-logo.svg";
import twitterLogo from "../staticfiles/svg/twitter-white-logo.svg";
import instagramLogo from "../staticfiles/svg/instagram-white-logo.svg";
import youtubeLogo from "../staticfiles/svg/youtube-white-logo.svg";
import MaterialLink from "@mui/material/Link";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          // pb: "50px",
          minHeight: "400px",
        }}
      >
        <Grid
          container
          sx={{
            height: "auto",
            display: "flex",
            justifyContent: "center",
            pt: "30px",
            // py: "50px",
          }}
        >
          <Grid
            item
            lg={4.8}
            md={3}
            sm={12}
            xs={12}
            sx={{
              // backgroundColor: "ButtonFace",
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              alignItems: ["flex-start", "center", "center", "center"],
              flexDirection: "column",
            }}
          >
            <MaterialLink
              component={NavLink}
              to="/"
              sx={{
                textDecoration: "none",
              }}
            >
              <img
                src={Logo}
                // height="100rem"
                style={{ maxWidth: "400px", width: "100%" }}
              />
            </MaterialLink>
            <Box
              sx={{
                mt: ["20px", "20px", "40px", "40px"],
                mb: ["20px", "20px", "", ""],
                backgroundColor: "",
                display: "flex",
                justifyContent: "space-around",
                mx: "20px",
                maxWidth: "250px",
                width: "100%",
              }}
            >
              <a
                href="https://www.instagram.com/studnerd"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={instagramLogo}
                  height="100%"
                  style={{ marginRight: "15px" }}
                  alt="Instagram Logo"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/studnerd"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={linkedinLogo}
                  height="100%"
                  style={{ marginRight: "15px" }}
                  alt="Linkedin Logo"
                />
              </a>
              <a
                href="https://twitter.com/studnerd_"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={twitterLogo}
                  height="100%"
                  style={{ marginRight: "15px" }}
                  alt="Twitter Logo"
                />
              </a>
              <a
                href="https://www.youtube.com/@studnerd"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={youtubeLogo}
                  height="100%"
                  style={{ marginRight: "15px" }}
                  alt="Youtube Logo"
                />
              </a>
              <a
                href="https://www.instagram.com/studnerd"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <img
                  src={facebookLogo}
                  height="100%"
                  // style={{ marginRight: "15px" }}
                  alt="Facebook Logo"
                />
              </a>
            </Box>
            {/* <img src={Logo} height="120px" /> */}
            {/* <img src={Logo} height="100%" /> */}
          </Grid>
          <Grid
            item
            lg={2.4}
            md={3}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "",
              pl: "15px",
              my: ["10px", "10px", "", ""],
            }}
          >
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontFamily: "Montserrat",
                color: "#66ccff",
              }}
            >
              Company
            </Typography>
            <List>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/about-us"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    About Us
                  </Typography>
                </MaterialLink>
              </ListItem>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/blogs"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    Blogs
                  </Typography>
                </MaterialLink>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            lg={2.4}
            md={3}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "",
              pl: "15px",
              my: ["10px", "10px", "", ""],
            }}
          >
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontFamily: "Montserrat",
                color: "#66ccff",
              }}
            >
              Community
            </Typography>
            <List>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    Studnerd.com
                  </Typography>
                </MaterialLink>
              </ListItem>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/help"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    Help Center
                  </Typography>
                </MaterialLink>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            lg={2.4}
            md={3}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "",
              pl: "15px",
              my: ["10px", "10px", "", ""],
            }}
          >
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontFamily: "Montserrat",
                color: "#66ccff",
              }}
            >
              Privacy & Safety
            </Typography>
            <List>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/privacy-policy"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </MaterialLink>
              </ListItem>
              <ListItem>
                <MaterialLink
                  component={NavLink}
                  to="/terms-of-service"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "light",
                    }}
                  >
                    Terms of Service
                  </Typography>
                </MaterialLink>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", backgroundColor: "black", pb: "20px" }}>
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontFamily: "Montserrat",
            color: "#dddddd",
          }}
        >
          © 2023 Studnerd
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
