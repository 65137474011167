import React, { useState, useEffect } from "react";
import { Typography, Box, Switch, Divider, Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import { baseUrl } from "../services/baseUrl";

const SettingsFeedStatus = (props) => {
  const { access_token } = getToken();

  const [feedStatus, setFeedStatus] = useState(1);
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  useEffect(() => {
    setFeedStatus(props.feedStatus);
  }, [props]);

  function handleFeedStatusChange(event) {
    setIsStatusUpdated(true);
    const newValue = parseInt(event.target.value);
    setFeedStatus(newValue);
    // console.log(newValue);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isStatusUpdated) {
      const formData = new FormData();
      formData.append("feed_status", feedStatus);
      // console.log(formData);
      fetch(`${baseUrl}/api/user/feedstatus-update/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Box
        id="test2"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          pl: "10px",
          pt: "20px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Feed Status</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="second"
            value={feedStatus}
            name="radio-buttons-group"
            sx={{
              "& .Mui-checked": {
                color: "#66CCFF",
              },
            }}
          >
            <FormControlLabel
              // value={1}
              value="disabled"
              disabled
              control={<Radio />}
              label="Imported Course Based"
              onChange={handleFeedStatusChange}
            />
            <FormControlLabel
              // value={2}
              value="disabled"
              disabled
              control={<Radio />}
              label="Timeline"
              onChange={handleFeedStatusChange}
            />
          </RadioGroup>
        </FormControl>
        <Divider />
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "bold",
            mt: "16px",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          Imported Course Based (Recommended)
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          Get the posts based on imported course from the accounts you follow
        </Typography>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "bold",
            mt: "4px",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          Timeline Based:
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          Get the lastest posts from the accounts you follow
        </Typography>
        <Divider />
        <Typography
          sx={{
            fontSize: "0.9em",
            fontWeight: "bold",
            mt: "4px",
            fontFamily:
              "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
          }}
        >
          This setting is currently not in use. We will update you as soon as
          it's online.
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "60px",
            alignItems: "center",
          }}
        >
          <Button
            disabled
            variant="contained"
            type="submit"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "#66CCFF",
              color: "black",
              boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
              border: "1px solid #dddddd",
              "&:hover": {
                border: "1px solid #dddddd",
                backgroundColor: "#87D7FF",
                boxShadow:
                  "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
              },
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SettingsFeedStatus;
