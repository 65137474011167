import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import "@videojs/themes/dist/forest/index.css";
// import "@videojs/themes/dist/fantasy/index.css";
// import "@videojs/themes/dist/city/index.css";
import "../css/videoplayer.css";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:

      // player.on("timeupdate", () => {
      //   const duration = player.duration();
      //   const currentTime = player.currentTime();
      //   const progress = (currentTime / duration) * 100;
      //   const seekBar = document.getElementsByClassName(
      //     "vjs-progress-holder vjs-slider"
      //   )[0];
      //   seekBar.setAttribute("aria-valuenow", progress);
      //   seekBar.style.width = `${progress}%`;
      // });
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      // player.src([options.sources, options.type]);

      player.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div
      data-vjs-player
      style={{
        paddingTop: "1rem",
        // paddingLeft: "1rem",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        ref={videoRef}
        className={`vjs-theme-fantasy`}
        // style={{ border: "1px solid black" }}
      />
    </div>
  );
};

export default VideoJS;

// import React from "react";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";

// export default class VideoJS extends React.Component {
//   // Instantiate a Video.js player when the component mounts
//   componentDidMount() {
//     this.player = videojs(this.videoNode, this.props, () => {
//       videojs.log("onPlayerReady", this);
//     });
//   }

//   // Dispose the player when the component will unmount
//   componentWillUnmount() {
//     if (this.player) {
//       this.player.dispose();
//     }
//   }

//   // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
//   // won't create additional wrapper in the DOM.
//   //
//   // See: https://github.com/videojs/video.js/pull/3856
//   render() {
//     return (
//       <div data-vjs-player>
//         <video
//           ref={(node) => (this.videoNode = node)}
//           className="video-js"
//         ></video>
//       </div>
//     );
//   }
// }
