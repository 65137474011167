import React, { useState, useEffect } from "react";
import { Typography, Box, Switch, Divider, Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import { baseUrl } from "../services/baseUrl";

const SettingsEmail = (props) => {
  const { access_token } = getToken();
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const [sendPromotionalEmails, setSendPromotionalEmails] = useState(false);
  const [sendFeedbackEmails, setSendFeedbackEmails] = useState(false);
  const [sendSupportEmails, setSendSupportEmails] = useState(false);
  const [sendTipsEmails, setSendTipsEmails] = useState(false);

  useEffect(() => {
    setSendPromotionalEmails(props.sendPromotionalEmails);
    setSendFeedbackEmails(props.sendFeedbackEmails);
    setSendSupportEmails(props.sendSupportEmails);
    setSendTipsEmails(props.sendTipsEmails);
  }, [props]);

  function handlePromotionalEmailsChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setSendPromotionalEmails(value);
    // console.log(value);
  }
  function handleFeedbackEmailsChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setSendFeedbackEmails(value);
    // console.log(value);
  }
  function handleSupportEmailsChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setSendSupportEmails(value);
    // console.log(value);
  }
  function handleTipsEmailsChange(event) {
    setIsStatusUpdated(true);
    const value = event.target.value;
    setSendTipsEmails(value);
    // console.log(value);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isStatusUpdated) {
      const formData = new FormData();
      formData.append("send_promotional_emails", sendPromotionalEmails);
      formData.append("send_feedback_emails", sendFeedbackEmails);
      formData.append("send_support_emails", sendSupportEmails);
      formData.append("send_tips_emails", sendTipsEmails);
      // console.log(formData);
      fetch(`${baseUrl}/api/user/email-notifications/`, {
        method: "PATCH",
        body: formData,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const styles = (theme) => ({
    radio: {
      "&$checked": {
        color: "#4B8DF8",
      },
    },
    checked: {},
  });
  return (
    <>
      <Box
        id="test2"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          p: "10px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="send-promotional-email"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Send Promotional Email
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="send-promotional-email"
              value={sendPromotionalEmails}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handlePromotionalEmailsChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handlePromotionalEmailsChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="send-feedback-email"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Send Feedback Email
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="send-feedback-email"
              value={sendFeedbackEmails}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handleFeedbackEmailsChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handleFeedbackEmailsChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="send-support-email"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Send Support Email
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="send-support-email"
              value={sendSupportEmails}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handleSupportEmailsChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handleSupportEmailsChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider />
        <Box sx={{ width: "100%", my: "5px" }}>
          <FormControl>
            <FormLabel
              id="send-tips-email"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.9rem",
                }}
              >
                Send Tips Email
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="send-tips-email"
              value={sendTipsEmails}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root": {
                  height: 15,
                  width: 15,
                },
                "& .Mui-checked": {
                  color: "#66CCFF",
                },
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    Yes
                  </Typography>
                }
                onChange={handleTipsEmailsChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontFamily:
                        "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.9rem",
                    }}
                  >
                    No
                  </Typography>
                }
                onChange={handleTipsEmailsChange}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "60px",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "#66CCFF",
              color: "black",
              boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
              border: "1px solid #dddddd",
              "&:hover": {
                border: "1px solid #dddddd",
                backgroundColor: "#87D7FF",
                boxShadow:
                  "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              Save Changes
            </Typography>
          </Button>
        </Box>
        {/* Email settings form here */}
        {/* <Typography>send promotional email </Typography> */}
        {/* <Typography>send tips email</Typography>
      <Typography>send feedback email</Typography>
      <Typography>support email</Typography> */}
      </Box>
    </>
  );
};

export default SettingsEmail;
