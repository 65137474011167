import React from "react";
import { Paper, Box, CssBaseline, Typography } from "@mui/material";
import Logo from "../../images/logo.png";
import Lottie from "lottie-react";
import loading from "../../lottie/loading.json";
// import Loading from "../../images/loading.gif";

const LoadingComponent = () => {
  return (
    <>
      <CssBaseline />
      <Paper
        sx={{
          justifyContent: "center",
          backgroundColor: "black",
          // backgroundColor: "#EBF3FF",
          // opacity: "0.5",
          // backdropFilter: "blur(5px)",
          minHeight: "100vh",
          height: "fit-content",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "40%",
          }}
        >
          <img src={Logo} width="100%" />
        </Box>{" "}
        <Lottie animationData={loading} loop={true} />
      </Paper>
    </>
  );
};

export default LoadingComponent;
