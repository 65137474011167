import {
  Button,
  CssBaseline,
  Paper,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import Navbar from "./Navbar";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import { baseUrl } from "../services/baseUrl";

const SettingsProfileEdit = (props) => {
  const { access_token } = getToken();

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [file, setFile] = useState(null);
  const [isProfileUploaded, setIsprofileUploaded] = useState(false);
  useEffect(() => {
    setUsername(props.username);
    setName(props.name);
    setBio(props.bio);
    setEmail(props.email);
    setProfilePic(props.profilePic);
    setFile(props.profilePic);
  }, [props]);

  const handleProfilePicChange = async (event) => {
    setIsprofileUploaded(true);
    setFile(URL.createObjectURL(event.target.files[0]));
    compressImage(event.target.files[0], (compressedFile) => {
      setProfilePic(compressedFile);
    });
  };

  const compressImage = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const img = new Image(); // Create an in-memory image element
      img.src = event.target.result;

      img.onload = function () {
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / img.width;
        const canvas = document.createElement("canvas"); // In-memory canvas
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Compress the image
        const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.7);

        // Convert the base64 image to a blob
        const byteString = atob(compressedDataUrl.split(",")[1]);
        const mimeString = compressedDataUrl
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });
        const compressedFile = new File([blob], file.name, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });

        callback(compressedFile); // Pass the compressed file to the callback
      };
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("name", name);
    formData.append("bio", bio);
    formData.append("email", email);
    if (isProfileUploaded) {
      if (profilePic !== null) {
        formData.append("profile_pic", profilePic);
      }
    }

    if (username.length < 3 || username.length > 15) {
      alert("Username must be between 3 and 15 characters long.");
      return;
    }

    if (!/^[a-z0-9_.]+$/.test(username)) {
      alert(
        "Username can only contain lowercase letters, numbers, periods and underscores."
      );
      return;
    }
    if (!username || username.trim() === "") {
      alert("Username cannot be empty.");
      return;
    }

    if (username[0] === ".") {
      alert("You cannot start username with dot");
      return;
    }

    if (username[username.length - 1] === ".") {
      alert("You cannot end username with dot");
      return;
    }
    // console.log(formData);
    let data = null;
    // try {
    //   const response = await fetch(`${baseUrl}/api/user/profile-update/`, {
    //     method: "PATCH",
    //     body: formData,
    //     headers: {
    //       Authorization: `Bearer ${access_token}`,
    //     },
    //   });
    //   console.log(response);
    //   console.log(response.data);
    //   console.log(response.status);
    //   if (!response.ok) {
    //     throw new Error(response.data);
    //   }
    //   data = response.json();
    // } catch (err) {
    //   console.log(err);
    //   alert(err.message);
    // }
    fetch(`${baseUrl}/api/user/profile-update/`, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.username) {
          if (
            data.username[0].includes("user with this username already exists.")
          ) {
            alert(data.username[0]);
          }
        }
        if (data.error) {
          if (data.error.username) {
            alert(data.error.username);
          }
          // if (res.error.data.errors.email_or_username) {
          //   setIsErrorForEmailUsername(true);
          //   setErrorMsgForEmailUsername("Email Cannot be Blank");
          // }
          // if (res.error.data.errors.password) {
          //   setIsErrorForPassword(true);
          //   setErrorMsgForPassword("Incorrect Password");
          // }

          // console.log(res.error.data.errors);
        }
        // console.log(data);
      })
      .catch((error) => {
        // console.log(error);
        alert(error);
        // console.log(error.username[0]);
      });
  };

  return (
    <>
      <CssBaseline></CssBaseline>
      {/* <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          // justifyContent: "center",
        }}
      > */}
      <Box
        id="profile-form"
        component="form"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        sx={{
          // pt: 0,
          display: "block",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          pr: "10%",
          pb: "15px",
          // backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            // backgroundColor: "lightcyan",
            minHeight: "160px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "70%",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: "8px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                alt="User Pic"
                src={file}
                sx={{ width: 100, height: 100 }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "5px" }}>
              <Typography
                sx={{
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {" "}
                Profile Pic
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              width: "30%",
              px: "8px",
            }}
          >
            <Button
              variant="contained"
              component="label"
              size="small"
              sx={{
                transition: "0.4s",
                textTransform: "none",
                height: "30px",
                backgroundColor: "#66CCFF",
                color: "black",
                boxShadow: "none",
                // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
                border: "1px solid #dddddd",
                "&:hover": {
                  border: "1px solid #dddddd",
                  backgroundColor: "#87D7FF",
                  boxShadow: "none",
                  // boxShadow:
                  //   "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Upload
              </Typography>
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleProfilePicChange}
              />
            </Button>
            {/* <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{ height: "25px" }}
              >
                <input hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: "60px",
            // backgroundColor: "lightcyan",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              justifyContent: "right",
              paddingRight: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "0.8rem",
              }}
            >
              Username
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              px: "10px",
            }}
          >
            {" "}
            <TextField
              size="small"
              fullWidth
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              InputProps={{
                style: {
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  color: "black",
                },
              }}
              sx={{}}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: "70px",

            // backgroundColor: "lightcyan",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              justifyContent: "right",
              paddingRight: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "0.8rem",
              }}
            >
              Name
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              px: "10px",
            }}
          >
            {" "}
            <TextField
              size="small"
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}
              InputProps={{
                style: {
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  color: "black",
                },
              }}
              sx={{}}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: "70px",

            // backgroundColor: "lightcyan",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              justifyContent: "right",
              paddingRight: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "0.8rem",
              }}
            >
              Bio
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              px: "10px",
            }}
          >
            {" "}
            <TextField
              size="small"
              fullWidth
              multiline
              rows={4}
              value={bio}
              onChange={(event) => setBio(event.target.value)}
              InputProps={{
                style: {
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  color: "black",
                },
              }}
              sx={{}}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: "70px",

            // backgroundColor: "lightcyan",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              justifyContent: "right",
              paddingRight: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "0.8rem",
              }}
            >
              Email ID
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              px: "10px",
            }}
          >
            {" "}
            <TextField
              size="small"
              fullWidth
              disabled
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{
                style: {
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  color: "black",
                },
              }}
              sx={{}}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            sx={{
              transition: "0.3s",
              textTransform: "none",
              backgroundColor: "#66CCFF",
              color: "black",
              boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
              border: "1px solid #dddddd",
              "&:hover": {
                border: "1px solid #dddddd",
                backgroundColor: "#87D7FF",
                boxShadow:
                  "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontFamily:
                  "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default SettingsProfileEdit;
