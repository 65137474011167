import React, { useState, useEffect } from "react";
import axios from "axios";
// import ReactQuill from "react-quill";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import { Box, Typography, Grid } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ReportIcon from "@mui/icons-material/Report";
import { Avatar, Button, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { getToken } from "../services/CookieStorageService";
// import { getToken } from "../services/LocalStorageService";
import { NavLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { baseUrl } from "../services/baseUrl";
import "react-quill/dist/quill.core.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .katex-html": {
      display: "none",
    },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& p": {
      fontFamily:
        "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
      // fontFamily:
      //   "Avenir Next Regular,sans-serif",
      margin: 0,
      color: "black",
      justifyContent: "center",
      "&$title": {
        margin: "0 0 16px",
      },
    },
  },
  title: {},
}));

const ViewNote = () => {
  const classes = useStyles();
  const { access_token } = getToken();
  const [isLoading, setIsLoading] = useState(true);
  // const navigate = useNavigate();

  const [post, setPost] = useState("");
  const [title, setTitle] = useState("");
  const { postID } = useParams();

  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [ownerName, setOwnerName] = useState("");
  const [ownerID, setOwnerID] = useState("");

  const [NotesData, setNotesData] = useState([]);
  const [Published, setPublished] = useState("");
  //   const { id } = props.postID;
  const [openSb, setOpenSb] = useState(false);
  // const [open2, setOpen2] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenSb(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSb(false);
  };

  const handleSubmitReport = (event) => {
    event.preventDefault();
    // const currentTime = playerRefForVote.current.currentTime();
    const reason_to_report = selectedValue;
    // console.log("this is reason to report", selectedValue);

    fetch(`${baseUrl}/api/posts/report-note/${postID}/${reason_to_report}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);
            setSelectedValue("");
            setOpenSb(true);
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    handleClose();
  };

  const handleUpvote = (event) => {
    event.preventDefault();
    // console.log(playerRefForVote.current);
    // const currentTime = playerRefForVote.current.currentTime();

    fetch(`${baseUrl}/api/posts/upvoteNote/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
      body: JSON.stringify({
        postID: postID,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);

            if (data.message === "rmup") {
              setUpvoted(false);
              setUpvoteCount((prevCount) => prevCount - 1);
            } else if (data.message === "aduprmdv") {
              setUpvoted(true);
              setDownvoted(false);
              setUpvoteCount((prevCount) => prevCount + 1);
            } else if (data.message === "adup") {
              setUpvoted(true);
              setUpvoteCount((prevCount) => prevCount + 1);
            }
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDownvote = (event) => {
    event.preventDefault();
    // const currentTime = playerRefForVote.current.currentTime();

    fetch(`${baseUrl}/api/posts/downvoteNote/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`, // assuming token is stored in localStorage
      },
      body: JSON.stringify({
        postID: postID,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log("this is response", data);
            if (data.message === "rmupaddv") {
              setUpvoted(false);
              setDownvoted(true);
              setUpvoteCount((prevCount) => prevCount - 1);
            } else if (data.message === "rmdv") {
              setDownvoted(false);
            } else if (data.message === "addv") {
              setDownvoted(true);
            }
          });
        }
        // playerRefForVote.current.currentTime(currentTime);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    // console.log("this is notes ID : ", postID);
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/posts/view-note/${postID}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response.data);
      if (response.data.data.html !== undefined) {
        setIsLoading(false);
        setPost(response.data.data.html);
        setTitle(response.data.data.title);
        setPublished(response.data.data.published);
        setUpvoteCount(response.data.total_upvotes);
        setViewCount(response.data.total_views);
        setOwnerName(response.data.data.owner.username);
        setOwnerID(response.data.data.owner.id);
        setUpvoted(response.data.is_upvoted);
        // console.log("this is response data", response.data);
      }
    } catch (error) {
      alert(error.response.data.error);
      setIsLoading(false);
      // setTimeout(() => {
      //   navigate(-1); // Navigates back
      // }, 3000);

      throw error;
    }
  };
  // console.log("this is post variable", post);

  return (
    <>
      <Snackbar
        open={openSb}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          This post has been Reported
        </Alert>
      </Snackbar>
      {!isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            px: "0.8rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              // backgroundColor: "#87D7FF",
              borderRadius: "4px",
              // boxShadow: "-4px -4px 5px #F9FBFE, 4px 4px 5px #C3CEDE",
              // boxShadow: "inset -3px -3px 4px #F9FBFE, inset 3px 3px 4px #9BB3C6",
              p: "8px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "blue",
                p: "8px",
              }}
            >
              <Box
                sx={{
                  width: "95%",
                  // backgroundColor: "#87D7FF",
                  // boxShadow: "-4px -4px 5px #F9FBFE, 4px 4px 5px #C3CEDE",
                  // height: "80px",
                  p: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    // "& .katex-html": {
                    //   display: "none",
                    // },
                    fontWeight: "medium",
                    color: "black",
                    // color: "#464646"
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: title }}
                    className={classes.root}
                  ></div>
                </Typography>
              </Box>
            </Box>
            {/* <Divider sx={{ ml: "5%", width: "90%" }} /> */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "red",
                p: "8px",
              }}
            >
              <Grid container sx={{ width: "100%", display: "flex" }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={
                      {
                        //  backgroundColor: "yellowgreen"
                      }
                    }
                  >
                    <Box>
                      <Box
                        sx={{
                          justifyItems: "center",
                          display: "flex",
                          // pt: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            color: "GrayText",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          Uploaded by&nbsp;
                        </Typography>
                        <Link
                          style={{ textDecoration: "none" }}
                          size="small"
                          component={NavLink}
                          to={`/profile/${ownerID}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              fontFamily:
                                "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                            }}
                          >
                            {ownerName}&nbsp;
                            {/* {owner.username} &nbsp; */}
                          </Typography>
                        </Link>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            color: "GrayText",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          On&nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          {/* {course.publish}  */}
                          {Published}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          // fontWeight: "bold",
                          fontFamily:
                            "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        }}
                      >
                        Total views: {viewCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                      // backgroundColor: "yellow",
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        sm: "flex-start",
                        md: "flex-end",
                        lg: "flex-end",
                      },
                    }}
                  >
                    {upvoted ? (
                      <Button
                        title="Remove upvote"
                        sx={{
                          borderRadius: "5px",
                          border: "1px solid ",
                          mx: "4px",
                          my: "5px",
                          backgroundColor: "#037AB5",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#66ccff",
                            // boxShadow: "-4px -4px 6px #FFFFFF,4px 4px 6px #000000",
                          },
                        }}
                        size="small"
                        onClick={handleUpvote}
                        startIcon={<ArrowCircleUpIcon />}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textTransform: "none",
                            fontSize: "0.8rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          Upvote &nbsp;{upvoteCount}
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        title="Upvote post"
                        sx={{
                          borderRadius: "5px",
                          border: "1px solid #037AB5",
                          mx: "4px",
                          my: "5px",
                          backgroundColor: "white",
                          color: "#037AB5",
                        }}
                        size="small"
                        onClick={handleUpvote}
                        startIcon={<ArrowCircleUpIcon />}
                      >
                        <Typography
                          sx={{
                            color: "#037AB5",
                            textTransform: "none",
                            fontSize: "0.7rem",
                            fontFamily:
                              "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                          }}
                        >
                          Upvote &nbsp;{upvoteCount}
                        </Typography>
                      </Button>
                    )}

                    <Button
                      title="Downvote post"
                      sx={{
                        borderRadius: "5px",
                        border: "1px solid gray",
                        mx: "4px",
                        my: "5px",
                        color: "gray",
                      }}
                      size="small"
                      onClick={handleDownvote}
                      startIcon={<ArrowCircleDownIcon />}
                    >
                      <Typography
                        sx={{
                          textTransform: "none",
                          fontSize: "0.7rem",
                          fontFamily:
                            "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        }}
                      >
                        Downvote
                      </Typography>
                    </Button>
                    <IconButton
                      title="Report post"
                      aria-label="report"
                      // color="warning"
                      sx={{ mx: "4px" }}
                      onClick={handleClickOpen}
                    >
                      <ReportIcon />
                    </IconButton>
                    <Dialog open={open} onClose={handleClose}>
                      {/* <DialogTitle>Subscribe</DialogTitle> */}
                      <DialogContent>
                        <DialogContentText>
                          Select Reason to Report the Video
                        </DialogContentText>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={selectedValue}
                            onChange={(event) =>
                              setSelectedValue(event.target.value)
                            }
                          >
                            {/* ('1', 'Spam'),
    ('2', 'Bullying or Harassment'),
    ('3', 'Copyright Infringement'),
    ('4', 'False Information'),
    ('5', 'Nudity or sexual Activity'),
    ('6', 'Hate speech or symbols'),
    ('7', 'Scams or fraud'),
    ('8', 'Violence or Self-injury'), */}

                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Spam"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="Bullying or Harassment"
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio />}
                              label="Copyright Infringement"
                            />
                            <FormControlLabel
                              value="4"
                              control={<Radio />}
                              label="False Information"
                            />
                            <FormControlLabel
                              value="5"
                              control={<Radio />}
                              label="Nudity or sexual Activity"
                            />
                            <FormControlLabel
                              value="6"
                              control={<Radio />}
                              label="Hate speech or symbols"
                            />
                            <FormControlLabel
                              value="7"
                              control={<Radio />}
                              label="Scams or fraud"
                            />
                            <FormControlLabel
                              value="8"
                              control={<Radio />}
                              label="Violence or Self-injury"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* add form here  */}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmitReport}>Submit</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* <Divider /> */}
          <Divider sx={{ ml: "5%", width: "90%" }} />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "red",
              py: "8px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "cyan",
                // boxShadow: "-4px -4px 5px #F9FBFE, 4px 4px 5px #C3CEDE",
                // boxShadow:
                // "inset -3px -3px 4px #F9FBFE, inset 3px 3px 4px #C3CEDE",
                p: "5px",
              }}
              className="view ql-editor"
            >
              <Typography
                dangerouslySetInnerHTML={{
                  __html: post,
                }}
                sx={{
                  "& .katex-html": {
                    display: "none",
                  },
                  // "& msub": {
                  //   display: "block",
                  //   textAlign: "center",
                  //   margin: "auto",
                  // },
                  '& msub mi:contains("lim")': {
                    display: "block",
                    textAlign: "center",
                    margin: "auto",
                  },
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {/* <div
                  dangerouslySetInnerHTML={{ __html: post }}
                  className={classes.root}
                ></div> */}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // height: "100%",
            height: "80vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "pink",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ViewNote;
