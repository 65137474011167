import { CssBaseline, Grid, Link } from "@mui/material";
// import {  Paper, Button } from "@mui/material";
import Navbar from "../Navbar";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SettingsProfileEdit from "../SettingsProfileEdit";
import SettingsFeedStatus from "../SettingsFeedStatus";
import SettingsEmail from "../SettingsEmail";
import SettingsNotifications from "../SettingsNotifications";
import SettingsAccount from "../SettingsAccount";
// import SettingsPrivacy from "../SettingsPrivacy";
import SettingsHelp from "../SettingsHelp";
import { withStyles } from "@mui/styles";
import { getToken } from "../../services/CookieStorageService";
// import { getToken } from "../../services/LocalStorageService";
// import { selectCurrentUserId } from "../../features/authSlice";
// import jwt_decode from "jwt-decode";
// import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../services/baseUrl";
const CustomTab = withStyles({
  root: {
    // paddingTop: "6px",
    // paddingBottom: "6px",
    transition: "0.3s",
    "&:hover": {
      // boxShadow: "inset -3px -3px 4px #F9FBFE, inset 3px 3px 4px #C3CEDE",
      backgroundColor: "#CBEFFF",
    },
    // backgroundColor: "orange",
  },
  selected: {
    color: "black",
    p: "3px",
    borderRadius: "8px",
    background: "#87D7FF",
    // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
    "&:hover": {
      // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
      backgroundColor: "#87D7FF",
    },
    // border: "1px solid lightblue",
  },
})(Tab);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "80%" }}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const AccountSettings = () => {
  const [value, setValue] = useState(0);
  const [userSettings, setUserSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { access_token } = getToken();
  const [userID, setUserID] = useState("");
  const [profilePic, setprofilePic] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [sendPromotionalEmails, setSendPromotionalEmails] = useState(false);
  const [sendFeedbackEmails, setSendFeedbackEmails] = useState(false);
  const [sendSupportEmails, setSendSupportEmails] = useState(false);
  const [sendTipsEmails, setSendTipsEmails] = useState(false);
  const [notificationForFollowers, setNotificationForFollowers] =
    useState(false);
  const [notificationForUpvotes, setNotificationForUpvotes] = useState(false);
  const [notificationForSyllabusImports, setNotificationForSyllabusImports] =
    useState(false);
  const [feedStatus, setFeedStatus] = useState("");
  //profilepic , username , name , email , bio ,

  const setUserInfoState = (data) => {
    if (data) {
      setprofilePic(data.profile_pic);
      setUsername(data.user.username);
      setName(data.user.name);
      setEmail(data.user.email);
      setBio(data.bio || "");
      setSendPromotionalEmails(data.send_promotional_emails);
      setSendFeedbackEmails(data.send_feedback_emails);
      setSendSupportEmails(data.send_support_emails);
      setSendTipsEmails(data.send_tips_emails);
      setNotificationForFollowers(data.notification_for_followers);
      setNotificationForUpvotes(data.notification_for_upvotes);
      setNotificationForSyllabusImports(data.notification_for_syllabus_imports);
      setFeedStatus(data.feed_status || "");
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const response = await fetch(
        `${baseUrl}/api/user/settings/`,
        // `http://127.0.0.1:8000/api/user/settings/${currentUserId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          // console.log("user Settings data", data);
          setUserSettings(data);
          setUserInfoState(data);
        })
        .catch((error) => {
          console.log(error);
        });

      setLoading(false);
    };
    fetchUserDetails();
  }, [access_token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <CssBaseline></CssBaseline>
      <Navbar></Navbar>
      <Grid
        container
        sx={{
          // height: "calc(100vh - 48px)",
          // alignItems: "center",
          // pt: "80px",
          pt: "9vh",
          height: "100vh",
          justifyContent: "center",
          // backgroundColor: "skyblue",
          backgroundColor: "#F3F9FF",
          // backgroundColor: "#EBF9FF",
          display: "flex",
        }}
      >
        {/* <Grid
          container
          // spacing={2}
          sx={{
            // backgroundColor: "skyblue",
            backgroundColor: "white",
            // pt: "100px",
            justifyContent: "center",
            height: "fit-content",

            //   height: "100vh",
            //   height: "calc(100vh - 48px)",
          }}
        > */}
        <Grid
          item
          lg={6}
          md={8}
          sm={12}
          xs={12}
          sx={{
            // width:"auto",
            backgroundColor: "white",
            // backgroundColor: "#EBF9FF",
            // background: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
            // background: "linear-gradient(to top, #0f0c29, #302b63, #24243e)",
            borderRadius: "30px",
            // border: "1px solid #eeeeee",

            // boxShadow: "-5px -5px 7px #F9FBFE, 5px 5px 7px #C3CEDE",
            // boxShadow: "3",
            // boxShadow: "-3px -3px red",
            // minHeight: "400px",
            // maxHeight: "500px",
            // maxWidth: "800px",
            height: "fit-content",
            p: "12px",
            // pt: "4px",
          }}
        >
          {" "}
          <Box
            sx={{
              // flexGrow: 1,
              // bgcolor: "background.paper",
              display: "flex",
              height: "fit-content",
              width: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  // backgroundColor: "black", transition: "none" ,
                  display: "none",
                },
              }}
              // aria-label="Vertical tabs example"
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "black",
                  // display: "none",
                },

                width: "25%",
                minWidth: "155px",
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Edit Profile"
                {...a11yProps(0)}
              />
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Change Password"
                {...a11yProps(1)}
              />
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Feed Status"
                {...a11yProps(2)}
              />
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Email"
                {...a11yProps(3)}
              />
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Notifications"
                {...a11yProps(4)}
              />
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Account"
                {...a11yProps(5)}
              />
              {/* <CustomTab
                  disableRipple
                  sx={{ textTransform: "none", color: "#4B4B4B" }}
                  label="Privacy"
                  {...a11yProps(6)}
                /> */}
              <CustomTab
                disableRipple
                sx={{
                  textTransform: "none",
                  color: "#4B4B4B",
                  borderRadius: "20px",
                  fontSize: "0.8rem",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
                label="Help and Support"
                {...a11yProps(6)}
              />
            </Tabs>
            <TabPanel
              value={value}
              index={0}
              sx={{ background: "blue", width: "65%" }}
            >
              <SettingsProfileEdit
                profilePic={profilePic}
                username={username}
                name={name}
                bio={bio}
                email={email}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Link
                component={NavLink}
                to="/change-password"
                sx={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                  }}
                >
                  Change Password
                </Typography>
              </Link>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SettingsFeedStatus feedStatus={feedStatus} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <SettingsEmail
                sendPromotionalEmails={sendPromotionalEmails}
                sendFeedbackEmails={sendFeedbackEmails}
                sendSupportEmails={sendSupportEmails}
                sendTipsEmails={sendTipsEmails}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <SettingsNotifications
                notificationForFollowers={notificationForFollowers}
                notificationForUpvotes={notificationForUpvotes}
                notificationForSyllabusImports={notificationForSyllabusImports}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <SettingsAccount />
            </TabPanel>
            {/* <TabPanel value={value} index={6}>
                <SettingsPrivacy />
              </TabPanel> */}
            <TabPanel value={value} index={6}>
              <SettingsHelp />
              {/* <Typography>help </Typography> */}
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
      {/* </Grid> */}
      {/* </Paper> */}

      {/*change the url*/}
    </>
  );
};

export default AccountSettings;
