import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
  Link,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import greenBox from "../images/greenBox.gif";
const NotesSearchResultCard = (props) => {
  const [noteId, setNoteId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [owner, setOwner] = useState("");
  const [uploadedOn, setUplaodedOn] = useState("");
  const [views, setViews] = useState("");
  const [upvotes, setUpvotes] = useState("");
  const [scrollType, setScrollType] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    if (typeof props.noteInfo.id !== "undefined") {
      setNoteId(props.noteInfo.id);
    }
    if (typeof props.noteInfo.title !== "undefined") {
      setTitle(props.noteInfo.title);
    }
    if (typeof props.noteInfo.content !== "undefined") {
      setContent(props.noteInfo.content);
    }
    if (typeof props.noteInfo.owner !== "undefined") {
      setOwner(props.noteInfo.owner);
    }
    if (typeof props.noteInfo.uploaded_on !== "undefined") {
      setUplaodedOn(props.noteInfo.uploaded_on);
    }
    if (typeof props.noteInfo.views !== "undefined") {
      setViews(props.noteInfo.views);
    }
    if (typeof props.noteInfo.upvotes !== "undefined") {
      setUpvotes(props.noteInfo.upvotes);
    }
  }, [props.noteInfo]);

  // useEffect(() => {
  //   if (typeof props.scrollType !== "undefined") {
  //     setScrollType(props.scrollType);
  //   }
  //   console.log(
  //     "setting scroll with posts",
  //     props.scrollType,
  //     props.scrollPosition
  //   );
  //   sessionStorage.setItem(
  //     `${props.scrollType}_scrollPosition`,
  //     props.scrollPosition.toString()
  //   );
  // }, [props.scrollType, props.scrollPosition]);

  const handleClick = () => {
    // console.log(
    //   "setting scroll with posts",
    //   props.scrollType,
    //   props.scrollPosition
    // );
    sessionStorage.setItem(
      `${props.scrollType}_scrollPosition`,
      props.scrollPosition.toString()
    );
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          // maxWidth: "500px",
          height: "200px",
          boxShadow: "none",
          backgroundColor: "white",
          borderRadius: "10px",
          border: "1px solid #F8F8F8",
          // px: "10px",
          // py: "10px",
          p: 0,
          mt: "7px",
        }}
      >
        <CardActionArea
          sx={{ width: "100%", height: "100%", minWidth: "260px", p: "10px" }}
          component={NavLink}
          onClick={handleClick}
          to={`/note/${noteId}`}
        >
          <CardContent sx={{ height: "100%", p: "3px" }}>
            <Box
              sx={{
                display: "flex",
                height: "fit-content",
                alignItems: "center",
                // backgroundColor: "silver",
                px: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "1.4rem",
                  fontWeight: "medium",
                  color: "black",
                  // color: "#5F5F5F",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "55%",
                // alignItems: "center",
                // backgroundColor: "yellowgreen",
                px: "8px",
                // backgroundColor: "yellow",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: "4px",
                  py: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.9rem",
                    mx: "3px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "4",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {content}
                </Typography>
              </Box>
            </Box>
            {/* <Divider /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "25%",
                px: "10px",
                mt: "6px",
                // alignItems: "center",
                // backgroundColor: "lightcoral",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // backgroundColor: "black",
                  height: "100%",
                  width: "70px",
                  py: "3px",
                }}
              >
                <Avatar src="#" sx={{ height: "100%", width: "55%" }}>
                  N
                </Avatar>
              </Box> */}
              <Box
                sx={{
                  justifyItems: "center",
                  display: "flex",
                  // pt: "5px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* Uploaded b */}
                  By&nbsp;
                </Typography>
                <Box
                  style={{ textDecoration: "none" }}
                  size="small"
                  // component={NavLink}
                  // to={`/profile/${props.noteInfo.note.owner.id}`}
                >
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      color: "GrayText",
                      fontFamily:
                        "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    }}
                  >
                    {owner}&nbsp;
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  on&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* {course.publish} 
                16 Mar, 2018 */}
                  {uploadedOn}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: "GrayText",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {views}&nbsp;views, {upvotes}
                &nbsp;Upvotes
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default NotesSearchResultCard;
