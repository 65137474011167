import { createSlice } from "@reduxjs/toolkit";

export const homeStateSlice = createSlice({
  name: "homeState",
  initialState: {
    isStateSaved: false,
    selectedTab: "",
    // currentScrollPosition: 0,
    selectedSubject: "",
    selectedTopic: "",
    isDrawerOpen: null,
    selectedTopicQuery: "",
  },
  reducers: {
    setCurrentState: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.selectedTab = action.payload.selectedTab;
      state.selectedSubject = action.payload.selectedSubject;
      state.selectedTopic = action.payload.selectedTopic;
    },
    setCurrentTab: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.selectedTab = action.payload.selectedTab;
    },
    // setCurrentScrollPosition: (state, action) => {
    //   state.isStateSaved = action.payload.isStateSaved;
    //   state.currentScrollPosition = action.payload.currentScrollPosition;
    // },
    setCurrentSubject: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.selectedSubject = action.payload.selectedSubject;
    },
    setCurrentTopic: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.selectedTopic = action.payload.selectedTopic;
    },
    setDrawerState: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.isDrawerOpen = action.payload.isDrawerOpen;
    },
    setSelectedTopicQuery: (state, action) => {
      state.isStateSaved = action.payload.isStateSaved;
      state.selectedTopicQuery = action.payload.selectedTopicQuery;
    },
  },
});

export const {
  setCurrentState,
  setCurrentTab,
  // setCurrentScrollPosition,
  setCurrentSubject,
  setCurrentTopic,
  setDrawerState,
  setSelectedTopicQuery,
} = homeStateSlice.actions;

export const selectHomeState = (state) => state.homeState;
export const selectCurrentTab = (state) => state.homeState.selectedTab;
// export const selectCurrentScrollPosition = (state) =>
//   state.homeState.currentScrollPosition;
export const selectCurrentSubject = (state) => state.homeState.selectedSubject;
export const selectCurrentTopic = (state) => state.homeState.selectedTopic;
export const selectCurrentDrawerState = (state) => state.homeState.isDrawerOpen;
export const selectCurrentSelectedTopicQuery = (state) =>
  state.homeState.selectedTopicQuery;

export default homeStateSlice.reducer;
