import { CssBaseline } from "@mui/material";
import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ViewNote from "../ViewNote";

const NoteViewer = () => {
  //   const [post, setPost] = useState("");
  //   const [title, setTitle] = useState("");
  //   const { postID } = useParams();
  //   //   const { id } = props.postID;

  //   useEffect(() => {
  //     console.log("this is notes ID : ", postID);
  //     fetchPost();
  //   }, []);

  //   const fetchPost = async () => {
  //     try {
  //       const response = await axios.post(
  //         `http://127.0.0.1:8000/api/posts/view-note/${postID}`,
  //         {
  //           headers: {
  //             //   Application: `Bearer ${access_token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       console.log(response.data.success);
  //       if (response.data.html !== undefined) {
  //         setPost(response.data.html);
  //         setTitle(response.data.title);
  //         console.log("this is response data", response.data.html);
  //       }
  //     } catch (error) {
  //       throw error;
  //     }
  //   };
  //   console.log("this is post variable", post);
  return (
    <>
      <CssBaseline />
      <Navbar></Navbar>
      <Grid
        container
        sx={{
          pt: "48px",
          minHeight: "100vh",
          height: "auto",
          justifyContent: "center",
          background: "#F3F9FF",
          // background: "#EBF3FF",
        }}
      >
        <Grid
          item
          lg={7}
          md={10}
          sm={12}
          xs={12}
          sx={{
            // backgroundColor: "lightblue",
            height: "100%",
            p: "10px",
          }}
        >
          <Box
            sx={{
              py: "3px",
              // boxShadow: "-3px -3px 5px #F9FBFE, 4px 4px 5px #C3CEDE",

              boxShadow: "none",
              // boxShadow: "3px 3px 4px #C3CEDE",
              // boxShadow:
              //   "inset -3px -3px 4px #F9FBFE, inset 3px 3px 4px #C3CEDE",
              // backgroundColor: "#EBF3FF",
              // backgroundColor: "#87D7FF",
              // border: "1px solid #85DFFF",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
          >
            <ViewNote></ViewNote>

            {/* <Box sx={{ display: "flex", p: "4px", justifyContent: "center" }}>
              {/* <ProfileSearchResultCard /> 
            </Box> */}
          </Box>
        </Grid>
      </Grid>
      {/* <h1>
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
      </h1>

      <div dangerouslySetInnerHTML={{ __html: post }}></div> */}
    </>
  );
};

export default NoteViewer;
