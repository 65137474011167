// A Beginners Guide on How to use Studnerd
import React from "react";
import {
  Box,
  Paper,
  CssBaseline,
  Grid,
  Divider,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
} from "@mui/material";
import MaterialLink from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../images/logo.png";

import blogsbg from "../../../images/studnerdblogs.png";
import Footer from "../../Footer";
import notesEditor from "../../../images/notesEditor.PNG";
import searchFilter from "../../../images/searchFilter.PNG";
import notesImported from "../../../images/notesImported.PNG";
import videoForm from "../../../images/videoForm.PNG";
import SyllabusListPage from "../../../images/SyllabusListPage.PNG";
import Syllabus from "../../../images/Syllabus.png";
import saveSyllabus from "../../../images/saveSyllabus.PNG";

const FirstBlog = () => {
  return (
    <>
      <CssBaseline></CssBaseline>
      {/* <BlankNavbar /> */}
      <Paper
        sx={{
          minHeight: "100vh",
          backgroundColor: "white",
          backgroundColor: "#E9F7FF",
          backgroundImage: `url(${blogsbg})`,
          backgroundSize: "cover", // Stretch the image to cover the entire container
          backgroundAttachment: "fixed",
        }}
      >
        <Grid
          container
          sx={{
            alignItems: "normal",
            height: "auto",
            // backgroundColor: "black",
            // minHeight: "100vh",
            // backgroundColor: "rgba(233, 247, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            // p: "15px",
          }}
        >
          <Box
            sx={{
              // alignContent: "right",
              width: "100%",
              // backgroundColor: "red",
              // backgroundColor: "rgba(255, 255, 255, 0.4)",
              // backdropFilter: "blur(5px)",
              height: "fit-content",
              p: "10px",
              //   display: "flex",
              //   justifyContent: "flex-end",
              // position: "fixed",
            }}
          >
            <Button
              component={Link}
              to="/blogs"
              sx={{
                textTransform: "none",
                borderRadius: "30px",
                p: "4px",
                px: "10px",
                fontWeight: "medium",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "1rem",
                backgroundColor: "white",
                border: "2px solid #66ccff",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  border: "2px solid black",
                  color: "#66ccff",
                  transition: "0.3s",
                },
              }}
            >
              Go to Blogs
            </Button>
            <Button
              component={Link}
              to="/"
              sx={{
                textTransform: "none",
                borderRadius: "30px",
                ml: "10px",
                p: "4px",
                px: "10px",
                fontWeight: "medium",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                fontSize: "1rem",
                backgroundColor: "#66ccff",
                border: "2px solid white",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  border: "2px solid #66ccff",
                  color: "#66ccff",
                  transition: "0.3s",
                },
              }}
            >
              Visit studnerd.com
            </Button>
          </Box>
          <Grid
            item
            lg={8}
            md={10}
            sm={12}
            xs={12}
            // id="container"
            sx={{
              // position: "relative",
              // backgroundColor: "red",
              // px: "20px",
              // justifyContent: "center",
              alignItems: "center",
              // display: "flex",
              borderRadius: "20px",
              // pt: "10px",
              px: "35px",
              mb: "50px",
              // p: 0,
              // m: 0,
            }}
          >
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "white",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                // backgroundColor: "rgba(100, 220,255, 0.2)",
                backdropFilter: "blur(8px)",
                px: "15px",
                py: "20px",
                borderRadius: "15px",
                textDecoration: "none",
                color: "",
                // color: "#474747",
                my: "15px",
                // transition: "0.8s",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "1.6rem",
                  fontWeight: "bold",
                }}
              >
                A Beginner's Guide: How to use Studnerd
              </Typography>

              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontFamily: "Montserrat",
                  color: "#747474",
                }}
              >
                By Vaibhav Rathod, 24<sup>th</sup> Jan, 2024
              </Typography>
              <Divider sx={{ my: "4px" }} />
              <Box sx={{ p: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Welcome to Studnerd, your go-to learning social network
                  designed to empower your educational journey. In this guide,
                  we'll walk you through the exciting features Studnerd offers.
                  Let's get started!!!
                </Typography>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  You must be familiar with the features that studnerd has to
                  offer, if not please check the{" "}
                  <Link to="/about-us">about us</Link> page of the website.
                </Typography>
                <br></br>
                {/* <Typography
                  sx={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Studnerd was started with the aim to create free and
                  customised online learning for individuals willing to learn
                  anything and everything.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Here users upload either their <strong>notes</strong> or&nbsp;
                  <strong>videos</strong> and people can browse and view them.
                  Studnerd also has a feature of importing{" "}
                  <strong>syllabus</strong>, which was created so that users,
                  especially students who are pursuining education in a certain
                  university can get their material based on the syllabus of
                  their academic syllabus.
                </Typography>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  Syllabus, the feature, is something which anyone can create
                  and users can import syllabus which is created by other users.
                  This syllabus helps the user to get notes and videos which are
                  only relevant to their academia.
                  <br></br>
                  For any additional information regarding these please check
                  the <Link to="/about-us">about us</Link> page of the website.
                </Typography> */}
                <Typography
                  sx={{ fontFamily: "Montserrat", fontSize: "1.1rem" }}
                >
                  1. Notes
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;Notes are just your regular notes. Nothing fancy here.
                  Notes here refer to user-generated documents or pieces of
                  content that contain educational information, explanations,
                  summaries, and insights on various topics. Just like what we
                  assume it to be. You read them.
                </Typography>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;1.A. Create Notes
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;The following image is how the studnerd's editor
                  looks like. Click on the "+" button on the upper right corner
                  of the screen on the home screen to get to this editor.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="notes editor image"
                    src={notesEditor}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp; Click{" "}
                  <Link to="/blogs/writing-notes-editor-walkthrough">here</Link>{" "}
                  to know more about the editor and its functionality.
                </Typography>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;1.B. Search Notes
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;Studnerd provides multiple ways to serve you
                  relevant notes. Let's go through them one by one.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;1.B.i Default notes page
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp;When you login, your home screen will have a
                  notes tab. By default based on your profile, relevant notes
                  are shown. These notes are just suggestion to what you might
                  want to read.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;1.B.ii Search bar
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp;Search bar allows you to look for relevant
                  notes as well as videos by default. If you wish to only see
                  notes in the search result, you can select the filter as notes
                  and click apply. The search results will now be curated only
                  to display notes as per your search query.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="search filter image"
                    src={searchFilter}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;1.B.iii Syllabus Import
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp;In the next part of blog we have discussed
                  in detail about what syllabi are and how to create them. For
                  now just assume the syllabus is just like an index of your
                  book where you click on any of the topic and get relevant
                  information about that topic in the form of notes or videos.
                  <br></br>
                  After you import a syllabus in your account, you just need to
                  be on the notes tab. That's it. After that as you select the
                  topic you want to read studnerd will serve you with the best
                  results for that topic.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="imported notes image"
                    src={notesImported}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <br></br>
                <Typography
                  sx={{ fontFamily: "Montserrat", fontSize: "1.1rem" }}
                >
                  2. Video Tutorials
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;According to ChatGPT, a video tutorial is an
                  instructional video that guides users through a specific task
                  or concept, providing step-by-step explanations and visual
                  demonstrations. It offers a dynamic and engaging way to learn,
                  combining audio and visual elements to convey information
                  effectively.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;Click{" "}
                  <Link to="/blogs/creating-video-tutorials">here</Link> to know
                  more about creating videos on studnerd
                </Typography>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;2.A. Upload Videos
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp; &emsp;Uploading tutorial videos on studnerd is easy.
                  Use this form to upload and publish your tutorial videos.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="video upload image"
                    src={videoForm}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;2.B. Search Videos
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;Just like notes, for videos studnerd has multiple
                  ways to serve you relevant tutorials.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;2.B.i Default Videos page
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp;When you login, your home screen will have a
                  videos tab. By default there are suggestions on the home
                  screen based on most relevant videos in the community.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;2.B.ii Search Bar
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp;Search bar allows you to look for relevant
                  notes as well as videos by default. If you wish to only see
                  videos in the search result, you can select the filter as
                  videos and click apply. The search results will now be curated
                  only to display videos as per your search query.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;2.B.iii Import Syllabus
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;&emsp; After you import a syllabus in your
                  account, you just need to be on the videos tab. After that as
                  you select the topic you want to read studnerd will serve you
                  with the best results for that topic.
                </Typography>
                <br></br>
                <Typography
                  sx={{ fontFamily: "Montserrat", fontSize: "1.1rem" }}
                >
                  3. Syllabus
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;3.A. Import Syllabus
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  {/* &emsp;&emsp; On your home screen, on
                  the left side you will see an "Import" button, once you
                  click on that button a list of syllabi present with studnerd
                  will appear along with the number of imports for each. You can
                  select the relevant syllabus if its visible upfront or search
                  it using the search bar above. */}
                  <ul>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      On your home screen, on the left side you will see an
                      "Import" button.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Once you click on the import button a list of syllabi
                      present with studnerd will appear along with the number of
                      imports for each.
                    </ListItem>
                  </ul>
                  <br></br>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      alt="list of syllabi image"
                      src={SyllabusListPage}
                      // height="100rem"
                      style={{
                        minWidth: "200px",
                        width: "50%",
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                  <br></br>
                  <ul>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      You can select the relevant syllabus if its visible
                      upfront or search it using the search bar above.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      Once you find the syllabus just click on it and press on
                      import.
                    </ListItem>
                  </ul>
                </Typography>

                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="view syllabus image"
                    src={Syllabus}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;3.B. Create Syllabus
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;The structure of a syllabus is designed in a way
                  to support multiple subject along with each subject's topics,
                  subtopics and a level below that. Following image is how the
                  create syllabus page looks like.
                </Typography>
                <br></br>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="save syllabus image"
                    src={saveSyllabus}
                    // height="100rem"
                    style={{
                      minWidth: "200px",
                      width: "50%",
                      border: "1px solid black",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <br></br>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;Once you fill in all the necessary data, click
                  save and the syllabus will be published where people
                  (including you) can browse and import the syllabus into their
                  profiles.
                </Typography>
                <br></br>

                <Typography
                  sx={{ fontFamily: "Montserrat", fontSize: "1.1rem" }}
                >
                  4. User Profiles
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;You can view a user's profile where you will see the
                  user's uploaded notes, videos and paths(syllabi).
                </Typography>
                <br></br>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;4.A. Follow Users
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;You can follow a user to see their posts. The feed
                  tab on the home page only shows the notes and videos of the
                  users that you follow.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;4.B. Feed
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;4.B.i Feed by default
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;By default feed shows the notes and videos of the
                  users that you follow based on the timeline. Which means you
                  see the latest posts on the top and see the older posts as you
                  scroll.
                </Typography>
                <Typography sx={{ fontFamily: "Montserrat" }}>
                  &emsp;&emsp;4.B.ii Feed after importing
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontFamily: "Montserrat",
                    fontWeight: "light",
                  }}
                >
                  &emsp;&emsp;Once you import the syllabus into your profile,
                  you can click on the topic that you want to learn and only the
                  notes and videos that are uploaded by the users that you
                  follow about the topic that you selected will be shown in the
                  feed. Make sure to keep feed tab selected for this.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Footer />
    </>
  );
};

export default FirstBlog;
