import {
  TextField,
  Box,
  Button,
  Typography,
  CssBaseline,
  Grid,
} from "@mui/material";
import React from "react";
import { getToken } from "../../services/CookieStorageService";
import { useState } from "react";
import { baseUrl } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const SetUsernamePage = () => {
  const { access_token } = getToken();
  const [username, setUsername] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("username", username);

    if (username.length < 3 || username.length > 15) {
      alert("Username must be between 3 and 15 characters long.");
      return;
    }

    if (!/^[a-z0-9_.]+$/.test(username)) {
      alert(
        "Username can only contain lowercase letters, numbers, periods and underscores."
      );
      return;
    }
    if (!username || username.trim() === "") {
      alert("Username cannot be empty.");
      return;
    }

    if (username[0] === ".") {
      alert("You cannot start username with dot");
      return;
    }

    if (username[username.length - 1] === ".") {
      alert("You cannot end username with dot");
      return;
    }

    fetch(`${baseUrl}/api/user/set-username/`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          // console.log(response);
          // const content = response.json();
          setIsError(true);
          setErrorMessage("Oops! Something went wrong. Try Again");
          // throw new Error(response.errors);
        } else {
          navigate("/home");
        }
        // return response.json();
      })
      .then((data) => {
        // console.log(data, "in then");

        if (data.username) {
          throw new Error(data.username[0]);
        }
      })
      .catch((error) => {
        console.log(error, "in catch");
        // alert(error);
        // console.log(error.username[0]);
      });
  };
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          p: "10px",
          backgroundColor: "#DCF3FF",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          // id="container"
          sx={
            {
              // justifyContent: "right",
              // display: "flex",
            }
          }
        >
          <Grid
            item
            lg={6}
            md={8}
            sm={10}
            xs={12}
            id="container"
            sx={{
              // position: "relative",
              // backgroundColor: "pink",
              px: ["20px", "40px", "60px", "80px"],
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mt: "50px",
                height: "200px",
                // backgroundColor: "blue",
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Let's find a username for you.
              </Typography>
              <Box
                sx={{
                  mt: "20px",
                  backgroundColor: "#E5F6FF",
                  borderRadius: "20px",
                  border: "1px solid white",
                  // height: "100px",
                  p: "20px",
                }}
              >
                <Box
                  id="profile-form"
                  component="form"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  sx={{
                    // pt: 0,
                    display: "block",
                    justifyContent: "center",
                    width: "100%",
                    height: "fit-content",
                    pr: "10%",
                    pb: "15px",
                    // backgroundColor: "lightgoldenrodyellow",
                  }}
                >
                  <TextField
                    size="small"
                    fullWidth
                    value={username}
                    error={isError}
                    helperText={errorMessage}
                    onChange={(event) => setUsername(event.target.value)}
                    InputProps={{
                      style: {
                        fontFamily:
                          "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        fontSize: "0.8rem",
                        color: "black",
                      },
                    }}
                    sx={{}}
                  />
                  <Typography
                    sx={{
                      mt: "15px",
                      fontSize: "0.8rem",
                    }}
                  >
                    * Username should be between 3 and 20 characters
                  </Typography>
                  <Typography
                    sx={{
                      mt: "5px",
                      fontSize: "0.8rem",
                    }}
                  >
                    * Numbers and lowercase alphabets allowed along with
                    underscores"_", hyphens"-" and periods"."
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      mt: "15px",
                      transition: "0.3s",
                      textTransform: "none",
                      backgroundColor: "#66CCFF",
                      color: "black",
                      boxShadow: "none",
                      // boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
                      border: "1px solid #dddddd",
                      "&:hover": {
                        boxShadow: "none",
                        border: "1px solid #dddddd",
                        backgroundColor: "#87D7FF",
                        // boxShadow:
                        //   "inset -2px -2px 2px #FFFFFF,inset 2px 2px 2px #777777",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontFamily:
                          "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                        color: "white",
                      }}
                    >
                      Continue
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SetUsernamePage;
