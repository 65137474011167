// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./baseUrl";
// Define a service using a base URL and expected endpoints
export const userAuthApi = createApi({
  reducerPath: "userAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/api/user/`,
  }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://127.0.0.1:8000/api/user/" }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (user) => {
        return {
          url: "register/",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: "login/",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    // getLoggedUser: builder.query({
    //   query: (access_token) => {
    //     return {
    //       url: "profile/",
    //       method: "GET",
    //       headers: {
    //         authorization: `Bearer ${access_token}`,
    //       },
    //     };
    //   },
    // }),
    changeUserPassword: builder.mutation({
      query: ({ actualData, access_token }) => {
        return {
          url: "change-password/",
          method: "POST",
          body: actualData,
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    sendPasswordResetEmail: builder.mutation({
      query: (user) => {
        return {
          url: "send-reset-password-email/",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ actualData, id, token }) => {
        return {
          url: `reset-password/${id}/${token}/`,
          method: "POST",
          body: actualData,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
  }),
});

// export const handleRegisterError = (error) => {
//   // const dispatch = useDispatch();
//   const navigate = useNavigate();

//   if (error.status === 400 && error.data === "User already exists") {
//     alert("User already exists. Please login.");
//     navigate("/login");
//   } else {
//     // Handle other error cases if needed
//     // dispatch(/* Your error action here */);
//   }
// };

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  // useGetLoggedUserQuery,
  useChangeUserPasswordMutation,
  useSendPasswordResetEmailMutation,
  useResetPasswordMutation,
} = userAuthApi;

// userAuthApi.endpoints.registerUser.onError = handleRegisterError;
