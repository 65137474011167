import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import greenBox from "../images/greenBox.gif";

const SuggestedVideos = (props) => {
  return (
    <>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "white",
          // backgroundColor: "#EBF9FF",
          width: "100%",
          // maxWidth: "500px",
          height: "120px",
        }}
      >
        <CardActionArea
          disableRipple
          component={NavLink}
          to={`/video/${props.videoInfo.id}`}
          sx={{ width: "100%", height: "100%" }}
        >
          <CardContent sx={{ height: "100%", display: "flex", p: "3px" }}>
            <Box
              sx={{
                backgroundColor: "silver",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                minWidth: "160px",
                borderRadius: "10px",
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                image={props.videoInfo.thumbnail}
                alt="Paella dish"
                sx={{
                  borderRadius: "10px",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "70%",
                p: "8px",
                //  backgroundColor: "pink"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "50%",
                  // alignItems: "center"
                }}
              >
                <Typography
                  sx={{
                    color: "#5F5F5F",
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    overflow: "hidden",
                    lineHeight: 1.2,
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    fontFamily: "sans-serif",
                    mx: "3px",
                  }}
                >
                  {props.videoInfo.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "50%",
                  alignItems: "left",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    mx: "3px",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  @{props.videoInfo.owner}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    mx: "3px",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  Total Views&nbsp;{props.videoInfo.views}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    mx: "3px",
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  Upvotes&nbsp;{props.videoInfo.upvotes}
                </Typography>
              </Box>
              {/* <Box
                sx={{ display: "flex", height: "40%", alignItems: "center" }}
              > */}
              {/* <Box
                sx={{ display: "flex", height: "20%", alignItems: "center" }}
              >
              </Box>
              <Box
                sx={{ display: "flex", height: "20%", alignItems: "center" }}
              >
              </Box> */}
              {/* </Box> */}
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default SuggestedVideos;
