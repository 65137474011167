// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   access_token: null,
// };
// export const authSlice = createSlice({
//   name: "auth_token",
//   initialState,
//   reducers: {
//     setUserToken: (state, action) => {
//       state.access_token = action.payload.access_token;
//     },
//     unSetUserToken: (state, action) => {
//       state.access_token = action.payload.access_token;
//     },
//   },
// });

// // Action creators are generated for each case reducer function
// export const { setUserToken, unSetUserToken } = authSlice.actions;

// export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    currentUserId: null, // Add this new property
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.currentUserId = action.payload.userId; // Update the currentUserId in login action
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.currentUserId = null; // Reset currentUserId to null in logout action
    },
    setCurrentUserId: (state, action) => {
      state.currentUserId = action.payload; // Add a new action to update currentUserId
    },
  },
});

export const { login, logout, setCurrentUserId } = authSlice.actions;

export const selectAuth = (state) => state.auth;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectCurrentUserId = (state) => state.auth.currentUserId; // Add a new selector

export default authSlice.reducer;
