import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Box,
  Avatar,
  Link,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import greenBox from "../images/greenBox.gif";
const DefaultNotesCard = (props) => {
  const handleClick = () => {
    // console.log(
    //   "setting scroll with posts",
    //   props.scrollType,
    //   props.scrollPosition
    // );
    sessionStorage.setItem(
      `${props.scrollType}_scrollPosition`,
      props.scrollPosition.toString()
    );
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          // maxWidth: "500px",
          height: "200px",
          boxShadow: "none",
          backgroundColor: "white",
          borderRadius: "10px",
          border: "1px solid #F8F8F8",
          // px: "10px",
          // py: "13px",
          p: "0px",
          mt: "7px",
          // border: "1px solid #5F5F5F",
        }}
      >
        <CardActionArea
          disableRipple
          disableTouchRipple
          onClick={handleClick}
          sx={{ width: "100%", height: "100%", minWidth: "260px", p: "10px" }}
          component={NavLink}
          to={`/note/${props.noteInfo.id}`}
        >
          <CardContent sx={{ height: "100%", p: "3px" }}>
            <Box
              sx={{
                display: "flex",
                height: "fit-content",
                alignItems: "center",
                // backgroundColor: "silver",
                px: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "1.4rem",
                  fontWeight: "medium",
                  color: "black",
                  // color: "#5F5F5F",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {props.noteInfo.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "55%",
                // alignItems: "center",
                // backgroundColor: "yellowgreen",
                px: "8px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: "4px",
                  py: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.9rem",
                    mx: "3px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "4",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {props.noteInfo.content}
                </Typography>
              </Box>
            </Box>
            {/* <Divider /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "25%",
                px: "10px",
                mt: "6px",
                // alignItems: "center",
                // backgroundColor: "lightcoral",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // backgroundColor: "black",
                  height: "100%",
                  width: "70px",
                  py: "3px",
                }}
              >
                <Avatar src="#" sx={{ height: "100%", width: "55%" }}>
                  N
                </Avatar>
              </Box> */}
              <Box
                sx={{
                  justifyItems: "center",
                  display: "flex",
                  // pt: "5px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* Uploaded b */}
                  By&nbsp;
                </Typography>
                <Box style={{ textDecoration: "none" }} size="small">
                  <Typography
                    // component={NavLink}
                    // to={`/profile/${props.noteInfo.note.owner.id}`}
                    sx={{
                      fontSize: "0.8rem",
                      color: "GrayText",
                      fontFamily:
                        "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    }}
                  >
                    {props.noteInfo.owner}&nbsp;
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  On&nbsp;
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "GrayText",
                    fontFamily:
                      "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  }}
                >
                  {/* {course.publish} 
                16 Mar, 2018 */}
                  {props.noteInfo.published}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: "GrayText",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                {props.noteInfo.view_count}&nbsp;views,{" "}
                {props.noteInfo.total_upvotes}&nbsp;Upvotes
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default DefaultNotesCard;
